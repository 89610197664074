import React, { useContext, useEffect, useState } from "react";
import PackageBox from "./components/PackageBox";
import { Alert, CircularProgress, Grid } from "@mui/material";
import { postRequest } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UpgradePackageBox from "./components/UpgradePackageBox";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { ASSET_IMAGES } from "app/utils/constants/paths";

const UpgradePackage = () => {
	const {t} = useTranslation();
  const {loginUserData} = useContext(CustomProvider)
  const [packages, setpackages] = useState([]);
  const [loading, setloading] = useState(true);

  const getPackagesList = () => {
    postRequest(
      "/getpackageslist",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setpackages(response?.data?.data);
          setloading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getPackagesList();
  }, []);

  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (

      <Grid container spacing={2} mt={2}>
        {
          loginUserData?.upgradeeligible === "no" ? (
            <>
            <Grid item sm={3}></Grid>
            <Grid item sm={6}>
              <Alert variant="filled" severity="warning"  >
                  You are not currently eligible for package upgrade
              </Alert>
            </Grid>
            </>

          )
          :
          (
            packages.map((pdata, index) => (
              <Grid sx={{ marginBottom: 3 }} item sm={4} key={index}>
                <UpgradePackageBox pdata={pdata} />
              </Grid>
            ))
          )
        }


    </Grid>

    
  );
};

export default UpgradePackage;
