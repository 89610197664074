import React, { useContext, useEffect, useRef, useState } from "react";
import "./tree.css";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MuiPopper from "@mui/material/Popper";
import { getBinaryTreeData } from "backendServices/ApiCalls";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Link, useNavigate, useParams } from "react-router-dom";
import Div from "@jumbo/shared/Div/Div";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { styled } from "@mui/material/styles";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import ReferralModal from "./ReferralModal";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { useTranslation } from "react-i18next";

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== "arrow",
})(({ theme, arrow }) => ({
  zIndex: 1,
  "& > div": {
    position: "relative",
  },
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const BinaryTree = () => {
  const { t } = useTranslation();
  const [userHierarchyData, setUserHierarchyData] = useState();
  const { loginUserData } = useContext(CustomProvider);
  const [loading, setLoading] = useState(true);
  const { randomcode } = useParams();
  // State for popover anchor element
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState([]);
  const containerRef = useRef(null); // Ref to the container element
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // Function to handle popover open
  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  // Function to handle popover close
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleNodeClick = (content, event) => {
    setPopoverContent(content);
    handlePopoverOpen(event);
  };

  let userData = loginUserData;
  let userrandomcode = userData?.randomcode;
  if (randomcode) {
    userrandomcode = randomcode;
  }
  const HierArchyData = () => {
    if (userrandomcode) {
      getBinaryTreeData(
        userrandomcode,
        (response) => {
          setUserHierarchyData(response?.data?.data);
          setLoading(false);
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
    }

  };

  const goBack = () => {
    navigate(-1);
  };


  useEffect(() => {
    setPopoverAnchorEl(null);
    HierArchyData();
  }, [loginUserData, randomcode]);

  useEffect(() => {
    // Attach click event listener to handle clicks outside the Popper
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        console.log("clicked");
        handlePopoverClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} md={12} lg={12}>
        <ReferralModal open={open} setOpen={setOpen} randomcode={popoverContent?.userrandomcode} />
        {randomcode && (
          <Button
            variant="contained"
            endIcon={<KeyboardDoubleArrowUpIcon />}
            size="small"
            onClick={()=>goBack()}
          >
            {t("pages.title.goBack")}
          </Button>
        )}
        <section
          className="management-hierarchy"
          id="tree"
          style={{ backgroundColor: "secondary" }}
        >
          <Popper
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            placement="right"
            disablePortal={false}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >


            {
              popoverContent?.userfullname ? (
                <JumboCardQuick title={""} noWrapper sx={{ mt: { xs: 2 }, pt: 2 }}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar src={userHierarchyData?.picturelink + (popoverContent?.profile_picture || "profile.png")} alt={popoverContent?.userfullname || 'NA'} />
                    <Typography variant="body1">
                      {popoverContent?.userfullname || 'NA'}
                    </Typography>
                  </Box>

                  <Grid container spacing={2} pb={2} alignContent='center' justifyContent={'center'} maxWidth={300}>
                    <Grid item xs={6}>
                      <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', fontSize: '1rem' }}>{t("pages.title.Left")}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', fontSize: '1rem' }}>{t("pages.title.Right")}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', fontSize: '1rem' }}>
                      {(parseFloat(popoverContent?.total_left_points || 0)).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', fontSize: '1rem' }}>
                      {(parseFloat(popoverContent?.total_right_points || 0)).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}

                      </Typography>
                    </Grid>



                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                      {
                        popoverContent.link ? (
                          <Button
                            component={Link}
                            to={popoverContent.link}
                            color="primary"
                            variant="contained"
                            size="small"
                          >
                            {t("pages.title.viewTree")}
                          </Button>
                        )
                          :
                          null
                      }

                    </Grid>

                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                      <Button
                        color="warning"
                        variant="contained"
                        size="small"
                        onClick={() => setOpen(true)}
                      >
                         {t("pages.title.enroll")}
                      </Button>
                    </Grid>

                  </Grid>
                </JumboCardQuick>
              )
                :
                null
            }

          </Popper>
          <Div className="hv-container" dir="ltr">
            <Div id="wrapper" className="hv-wrapper">
              {/* start */}
              {/* Key component */}
              <Div
                id="container"
                className="hv-item custom_zoom"
                ref={containerRef}
              >
                <Div className="hv-item-parent">
                  <Div
                    style={{ textDecoration: "none" }}
                    className="person"
                    onClick={(event) =>
                      handleNodeClick(
                        {
                          total_left_points: userHierarchyData?.user1?.total_left_points, 
                          total_right_points: userHierarchyData?.user1?.total_right_points,
                          profile_picture: userHierarchyData?.user1?.picture,
                          userrandomcode: userHierarchyData?.user1?.randomcode,
                          
                          userfullname: userHierarchyData?.user1?.fullname,
                          sponsorfullname:
                            userHierarchyData?.user1?.sponsorfullname,
                          link:
                            userHierarchyData?.user1?.randomcode &&
                            "/binary-tree/" +
                            userHierarchyData?.user1?.randomcode,
                        },
                        event
                      )
                    }
                  >
                    <img
                      src={
                        userHierarchyData?.picturelink +
                        (userHierarchyData?.user1?.picture ||
                          "profile.png")
                      }
                      alt=""
                      style={{ marginBottom: 5, width: 60, height: 60 }}
                    />
                    <p
                      className="name"
                      style={{ backgroundColor: "#04585a", color: "white" }}
                    >
                      {userHierarchyData?.user1?.username || t("pages.title.empty")}
                    </p>
                  </Div>
                </Div>
                <Div className="hv-item-children">
                  <Div className="hv-item-child">
                    {/* Key component */}
                    <Div className="hv-item">
                      <Div className="hv-item-parent">
                        <Div
                          style={{ textDecoration: "none" }}
                          className="person"
                          onClick={(event) =>
                            handleNodeClick(
                              {
                                total_left_points: userHierarchyData?.user2?.total_left_points,
                                total_right_points: userHierarchyData?.user2?.total_right_points,
                                profile_picture: userHierarchyData?.user2?.picture,
                                userrandomcode: userHierarchyData?.user2?.randomcode,
                                

                                userfullname:
                                  userHierarchyData?.user2?.fullname,
                                sponsorfullname:
                                  userHierarchyData?.user2?.sponsorfullname,
                                link:
                                  userHierarchyData?.user2?.randomcode &&
                                  "/binary-tree/" +
                                  userHierarchyData?.user2?.randomcode,
                              },
                              event
                            )
                          }
                        >
                          <img
                            src={
                              userHierarchyData?.picturelink +
                              (userHierarchyData?.user2?.picture ||
                                "profile.png")
                            }
                            style={{ marginBottom: 5, width: 60, height: 60 }}
                          />
                          <p
                            className="name"
                            style={{
                              backgroundColor: "#04585a",
                              color: "white",
                            }}
                          >
                            {userHierarchyData?.user2?.username || t("pages.title.empty")}
                          </p>
                        </Div>
                      </Div>

                      <Div className="hv-item-children">
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="hv-item-parent">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user4?.total_left_points, total_right_points: userHierarchyData?.user4?.total_right_points,
                                        profile_picture: userHierarchyData?.user4?.picture,
                                        userrandomcode: userHierarchyData?.user4?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user4?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user4
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user4
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user4
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user4
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user4?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                              <Div className="hv-item-children">
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user8?.total_left_points, total_right_points: userHierarchyData?.user8?.total_right_points,
                                        profile_picture: userHierarchyData?.user8?.picture,
                                        userrandomcode: userHierarchyData?.user8?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user8?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user8
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user8
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user8
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user8
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user8?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>

                              
                            </Div>
                          </Div>
                        </Div>
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user9?.total_left_points, total_right_points: userHierarchyData?.user9?.total_right_points,
                                        profile_picture: userHierarchyData?.user9?.picture,
                                        userrandomcode: userHierarchyData?.user9?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user9?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user9
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user9
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user9
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user9
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user9?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                              
                            </Div>
                          </Div>
                        </Div>
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <Div className="hv-item-parent">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user5?.total_left_points, total_right_points: userHierarchyData?.user5?.total_right_points,
                                        profile_picture: userHierarchyData?.user5?.picture,
                                        userrandomcode: userHierarchyData?.user5?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user5?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user5
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user5
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user5
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user5
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user5?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>

                              <Div className="hv-item-children">
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user10?.total_left_points, total_right_points: userHierarchyData?.user10?.total_right_points,
                                        profile_picture: userHierarchyData?.user10?.picture,
                                        userrandomcode: userHierarchyData?.user10?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user10?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user10
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user10
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user10
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user10
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user10?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>

                              
                            </Div>
                          </Div>
                        </Div>
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user11?.total_left_points, total_right_points: userHierarchyData?.user11?.total_right_points,
                                        profile_picture: userHierarchyData?.user11?.picture,
                                        userrandomcode: userHierarchyData?.user11?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user11?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user11
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user11
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user11
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user11
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user11?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>

                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                  <Div className="hv-item-child">
                    {/* Key component */}
                    <Div className="hv-item">
                      <Div className="hv-item-parent">
                        <Div
                          style={{ textDecoration: "none" }}
                          className="person"
                          onClick={(event) =>
                            handleNodeClick(
                              {
                                total_left_points: userHierarchyData?.user3?.total_left_points, total_right_points: userHierarchyData?.user3?.total_right_points,
                                profile_picture: userHierarchyData?.user3?.picture,
                                userrandomcode: userHierarchyData?.user3?.randomcode,
                                

                                userfullname:
                                  userHierarchyData?.user3?.fullname,
                                sponsorfullname:
                                  userHierarchyData?.user3?.sponsorfullname,
                                link:
                                  userHierarchyData?.user3?.randomcode &&
                                  "/binary-tree/" +
                                  userHierarchyData?.user3?.randomcode,
                              },
                              event
                            )
                          }
                        >
                          <img
                            src={
                              userHierarchyData?.picturelink +
                              (userHierarchyData?.user3?.picture ||
                                "profile.png")
                            }
                            style={{ marginBottom: 5, width: 60, height: 60 }}
                          />
                          <p
                            className="name"
                            style={{
                              backgroundColor: "#04585a",
                              color: "white",
                            }}
                          >
                            {userHierarchyData?.user3?.username || t("pages.title.empty")}
                          </p>
                        </Div>
                      </Div>
                      <Div className="hv-item-children">
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="hv-item-parent">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user6?.total_left_points, total_right_points: userHierarchyData?.user6?.total_right_points,
                                        profile_picture: userHierarchyData?.user6?.picture,
                                        userrandomcode: userHierarchyData?.user6?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user6?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user6
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user6
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user6
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user6
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user6?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                              <Div className="hv-item-children">
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user12?.total_left_points, total_right_points: userHierarchyData?.user12?.total_right_points,
                                        profile_picture: userHierarchyData?.user12?.picture,
                                        userrandomcode: userHierarchyData?.user12?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user12?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user12
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user12
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user12
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user12
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user12?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>

                              
                            </Div>
                          </Div>
                        </Div>
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user13?.total_left_points, total_right_points: userHierarchyData?.user13?.total_right_points,
                                        profile_picture: userHierarchyData?.user13?.picture,
                                        userrandomcode: userHierarchyData?.user13?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user13?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user13
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user13
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user13
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user13
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user13?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                            </Div>
                          </Div>{" "}
                        </Div>
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="hv-item-parent">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user7?.total_left_points, total_right_points: userHierarchyData?.user7?.total_right_points,
                                        profile_picture: userHierarchyData?.user7?.picture,
                                        userrandomcode: userHierarchyData?.user7?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user7?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user7
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user7
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user7
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user7
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user7?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                              <Div className="hv-item-children">
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div className="hv-item">
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user14?.total_left_points, total_right_points: userHierarchyData?.user14?.total_right_points,
                                        profile_picture: userHierarchyData?.user14?.picture,
                                        userrandomcode: userHierarchyData?.user14?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user14?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user14
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user14
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user14
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user14
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user14?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>

                              
                            </Div>
                          </Div>
                        </Div>
                        <Div className="hv-item-child">
                          <Div className="hv-item-child">
                            {/* Key component */}
                            <Div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <Div className="">
                                <Div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={(event) =>
                                    handleNodeClick(
                                      {
                                        total_left_points: userHierarchyData?.user15?.total_left_points, total_right_points: userHierarchyData?.user15?.total_right_points,
                                        profile_picture: userHierarchyData?.user15?.picture,
                                        userrandomcode: userHierarchyData?.user15?.randomcode,
                                        

                                        userfullname:
                                          userHierarchyData?.user15?.fullname,
                                        sponsorfullname:
                                          userHierarchyData?.user15
                                            ?.sponsorfullname,
                                        link:
                                          userHierarchyData?.user15
                                            ?.randomcode &&
                                          "/binary-tree/" +
                                          userHierarchyData?.user15
                                            ?.randomcode,
                                      },
                                      event
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.picturelink +
                                      (userHierarchyData?.user15
                                        ?.picture || "profile.png")
                                    }
                                    style={{
                                      marginBottom: 5,
                                      width: 60,
                                      height: 60,
                                    }}
                                  />
                                  <p
                                    className="name"
                                    style={{
                                      backgroundColor: "#04585a",
                                      color: "white",
                                    }}
                                  >
                                    {userHierarchyData?.user15?.username ||
                                      t("pages.title.empty")}
                                  </p>
                                </Div>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                            </Div>
                          </Div>{" "}
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
              
              {/* end */}
            </Div>
          </Div>
        </section>
      </Grid>
    </Grid>
  );
};

export default BinaryTree;
