import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Autocomplete, Box, TextField, FormControl, Grid } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div/Div";
import { postRequest } from "backendServices/ApiCalls";
import { useState } from "react";
import { CKEditor } from "ckeditor4-react"; // Import CKEditor
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  title: yup.string("Enter title").required("title is required"),
  message: yup.string("Enter message").required("message is required"),
});

const SendMessage = () => {
  const {t} = useTranslation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [userid, setUserid] = React.useState("");

  const [usersdata, setUsersData] = useState([]);
  let params = {
    status: "all",
  };
  const UsersData = () => {
    postRequest(
      "/getallusers",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setUsersData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);
  const onSubmitForm = (data, setSubmitting, resetForm) => {
    let params = {
      username: "admin",
      title: data.title,
      message: data.message,
    };
    postRequest(
      "/sendmessage",
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: `${t("pages.title.messagesent")}`,
            variant: "success",
          });
          setSubmitting(false);
          resetForm();
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t("pages.title.SendMessageToAdmin")}>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          <List disablePadding>
            <Formik
              validateOnChange={true}
              enableReinitialize="true"
              initialValues={{
                username: "",
                title: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                onSubmitForm(data, setSubmitting, resetForm);
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <JumboTextField
                        fullWidth
                        name="title"
                        label={t("pages.title.EnterTitle")}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CKEditor
                        initData={values.message}
                        onChange={(event) =>
                          setFieldValue("message", event.editor.getData())
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.SendMessage")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </List>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default SendMessage;
