import React, { useEffect, useState } from 'react';
import ArticleItem from "./ArticleItem";
import List from "@mui/material/List";
import {popularArticles} from "./data";
import { postRequest } from 'backendServices/ApiCalls';

const ArticlesList = () => {
    const [news,setNews] = useState([]);
    const News =()=>{
      postRequest('/news', '',(response) => {
          setNews(response?.data?.news)
      }, (error) => {
          console.log(error?.response?.data);
      })
  }
  useEffect(()=>{
    News();
  },[])
    return (
        <List disablePadding>
            {
                news.map((item, index) => (
                    <ArticleItem article={item} key={index}/>
                ))
            }
        </List>
    );
};

export default ArticlesList;
