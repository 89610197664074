import React, { useContext } from "react";
import CardHeader from "@mui/material/CardHeader";
import {
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const PortfolioBalance = (props) => {
  const { t } = useTranslation();
  const { loginUserData } = useContext(CustomProvider);
  const date = new Date(); //Get the day of the week of particular date mentioned by the user
  var day = date.getDay();
  var day1 = date.getDate();
  day = (day / 7) * 100;
  day1 = (day1 / 30) * 100;
  const dayp = day + "%";
  const day1p = day1 + "%";
  return (
    <Card>
      <CardHeader title={t("pages.title.PointsSummary")} />
      <CardContent sx={{ pt: 2 }}>
        <Grid container spacing={2}>
        <Grid item  xs={6}>
            <Typography variant={"h5"} color={"text.secondary"} mb={2}>
              {t("pages.title.AvailablePoints")}
            </Typography>
            <Typography variant={"h6"} color={"text.primary"}>
              {t("pages.title.LeftLeg")}
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                {loginUserData?.left_points}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"success"}
              value={100}
              sx={{
                width: { dayp },
                borderRadius: 4,
                height: 5,
                mb: 2,
                backgroundColor: "#E9EEEF",
              }}
            />
            <Typography variant={"h6"} color={"text.primary"}>
              {t("pages.title.RightLeg")}
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                {loginUserData?.right_points}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"warning"}
              value={100}
              sx={{
                width: { day1p },
                borderRadius: 4,
                height: 5,
                backgroundColor: "#E9EEEF",
              }}
            />
          </Grid>

          <Grid item  xs={6}>
            <Typography variant={"h5"} color={"text.secondary"} mb={2}>
              {t("pages.title.PendingPoints")}
            </Typography>
            <Typography variant={"h6"} color={"text.primary"}>
              {t("pages.title.LeftLeg")}
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                {loginUserData?.totalPendingLeftPoints}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"success"}
              value={100}
              sx={{
                width: { dayp },
                borderRadius: 4,
                height: 5,
                mb: 2,
                backgroundColor: "#E9EEEF",
              }}
            />
            <Typography variant={"h6"} color={"text.primary"}>
              {t("pages.title.RightLeg")}
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                {loginUserData?.totalPendingRightPoints}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"warning"}
              value={100}
              sx={{
                width: { day1p },
                borderRadius: 4,
                height: 5,
                backgroundColor: "#E9EEEF",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ pt: 0.5, pb: 2 }}>
        <Typography variant={"h6"} color={"text.primary"}>
          {t("pages.title.TotalConverted")}
          <Typography
            sx={{ borderLeft: 1, ml: 1, pl: 1 }}
            component={"span"}
            color={"text.secondary"}
          >
            {loginUserData?.converted_points}
          </Typography>
        </Typography>
        <LinearProgress
          variant={"determinate"}
          color={"success"}
          value={day}
          sx={{
            width: { dayp },
            borderRadius: 4,
            height: 5,
            mb: 2,
            backgroundColor: "#E9EEEF",
          }}
        />
      </CardActions>
    </Card>
  );
};

export default PortfolioBalance;