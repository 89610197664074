import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ViewIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MessageDetails from "./MessageDetails";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import ChatBox from "./ChatBox";
import { Chip, CircularProgress } from "@mui/material";

const Inbox = () => {
  const {t} = useTranslation();
  const [messages, setMessagesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [loadingRows, setLoadingRows] = useState({});

  const messagesData = () => {
    let params = {
      type: "receiver",
      userid: "admin",
    };
    postRequest(
      "/getmessages",
      params,
      (response) => {
        setMessagesData(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    messagesData();
  }, []);

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "senderusername",
      headerName: `${t("pages.title.Sender")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value === null ? "Admin" : params.value;
      },
    },
    {
      field: "title",
      headerName: `${t("pages.title.Title")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 400,
      
      groupable: false,
      aggregable: false,
    },

    {
      field: "mstatus",
      headerName: `${t("pages.title.Status")}`,
      width: 200,
      renderCell: (params) => (
        <Chip label={params.value} color={params.value === "seen" ? 'warning' : 'success'} size='small' />
      ),
    },

    {
      field: "createdat",
      headerName: `${t("pages.title.Date")}`,
      width: 200,
      renderCell: (params) => {
        return format(new Date(params.row.createdat), "MM/dd/yyyy");
      },
    },
    {
      field: "actions",
      headerName: `${t("pages.title.Actions")}`,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleViewClick(params.row)}
          color="primary"
          disabled={loadingRows[params.row.id]} // Disable the button when loading
        >
          {loadingRows[params.row.id] ? <CircularProgress size={24} /> : <ViewIcon />}
        </IconButton>
      ),
    },
  ];
  const handleViewClick = async (row) => {
    await setLoadingRows(prevLoadingRows => ({
      ...prevLoadingRows,
      [row.id]: true, // Assuming you have a unique identifier like 'id' for each row
    }));

    await  postRequest(
      "/updatemessage",
      {msgId:row?.id, msgCode:row?.randomcode},
      async (response) => {

      },(error) => {

        
        console.log(error?.response?.data);
      }
    );
    await setSelectedMessage(row);
    await setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const rows = messages;

  return (
    <JumboDemoCard
      title={t("pages.title.InboxMessage")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 12 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[12, 24, 48, 92]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <ChatBox selectedMessage={selectedMessage} setLoadingRows={setLoadingRows} setMessagesData={setMessagesData} messages={messages} />            
          </DialogContent>
          <DialogActions>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </Box>
    </JumboDemoCard>
  );
};

export default Inbox;
