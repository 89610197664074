import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Paper, Typography, TextField, Divider, Button, CircularProgress, Autocomplete } from '@mui/material'
import { getproduct, postRequest } from 'backendServices/ApiCalls';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Form, Formik } from "formik";
import * as yup from "yup";
import { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import Div from '@jumbo/shared/Div/Div';

const validationSchema = yup.object({
  firstname: yup.string().required('First Name is required'),
  lastname: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  mobile: yup.string().required('Mobile is required'),
  address: yup.string().required('Address is required'),
  country: yup
    .object()
    .required('country is required'),
  state: yup
    .object()
    .required('state is required'),
    city: yup
    .object()
    .required("city is required")
});



const Checkout = () => {


  const [imageurl, setImageUrl] = useState(null);

  const nav = useNavigate();
  const {  loginUserData,cartItems, setCartItems } = useContext(CustomProvider);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: ""
  })
  const [countrieslList, setCountriesList] = useState([]);
  const [stateslList, setStatesList] = useState([]);
  const [citieslList, setCitiesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shippingCharges, setShippingCharges] = useState('--');
  const [totalAmount, setTotalAmount] = useState('--');
  const [btnDisabled, setBtnDisabled] = useState(true);
  

  const GetAllProducts = () => {

    getproduct((response) => {
      setImageUrl(response?.data?.imageURL)
      if (response?.data === "success") { // change in everyone
        console.log("response get Successfully");
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  }


  const handleSubmit = (data, setSubmitting, resetForm) => {

    localStorage.setItem('orderMailingDetails', JSON.stringify(data))
    console.log(data)
    nav('/payment')

    }

    const fetchCountriesList = () => {

      postRequest('/getcountries','', (response) => {
        setCountriesList(response?.data?.data);
        setIsLoading(false)
      });
    };
  

  
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
    GetAllProducts();
    fetchCountriesList();

  }, []);
  //const subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  const { subtotal, totalWeight } = cartItems.reduce(
    (accumulators, item) => {
      // Calculate the total price
      accumulators.subtotal += item.price * item.quantity;
  
      // Calculate the total product weight
      accumulators.totalWeight += item.weight * item.quantity;
  
      return accumulators;
    },
    { subtotal: 0, totalWeight: 0 }
  );

  const initialValues = {
    firstname: loginUserData.firstname || '',
    lastname: loginUserData.lastname || '',
    email: loginUserData.email || '',
    mobile: loginUserData.mobile || '',
    address: loginUserData.address ||  '',
  };

  if(isLoading)
  {
      return  <Div
      sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
      }}
    >
      <CircularProgress sx={{m: '-40px auto 0'}}/>
    </Div>
  }
  
  return (
    <>
      <Container>
        <Typography p={2} variant='h3' color='#F39711' textAlign='center' mb={2} bgcolor='#272727'> CheckOut </Typography>
        <Formik
        enableReinitialize={true}
                            validateOnChange={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting,values,setFieldValue,touched,errors }) => (
                                <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
<Grid container spacing={2} >
                <Grid item xs={6} md={7}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        name="firstname"
                        label="First Name"
                        type="text"
                      />

                    </Grid>
                    <Grid item sm={6} xs={12} >
                      <JumboTextField
                        fullWidth
                        label="Last Name"
                        name='lastname'
                        type="text"
                      />

                    </Grid>

                    <Grid item sm={6} sx={{ width: { xs: '100%' } }} >
                      <JumboTextField
                        fullWidth
                        label="Email"
                        name='email'
                        type='email'
                      />

                    </Grid>


                    <Grid item sm={6} sx={{ width: { xs: '100%' } }} >
                      <JumboTextField
                        fullWidth
                        label="Mobile"
                        name='mobile'
                        type='tel'
                      />

                    </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.country}
                      onChange={(event, newValue) => {
                        setFieldValue('country',newValue || null);
                        postRequest('/getstates',{ countryid: newValue?.id }, (response) => {
                        if(response?.data?.status === "success")
                          {
                            setStatesList(response?.data?.data);
                          }
                        });
                      }}
                      id="vehmake-select"
                      options={countrieslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="country"
                          label="Select Country"
                          variant="outlined"
                          error={touched.country && errors.country}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.state}
                      onChange={(event, newValue) => {
                        setFieldValue('state',newValue);
                        postRequest('/getcities',{ stateid: newValue?.id }, (response) => {
                          if(response?.data?.status === "success")
                            {
                              setCitiesList(response?.data?.data);
                            }else{
                              setCitiesList([{id:newValue?.id, name:newValue?.name }]);
                            }
                          });
                      }}
                      id="vehmake-select"
                      options={stateslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state"
                          label="Select state"
                          variant="outlined"
                          error={touched.state && errors.state}
                          helperText={touched.state && errors.state}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setFieldValue('city',newValue);
                        const params = { 
                          countryid: values?.country?.id,  
                          stateid: values?.state?.id,
                          cityid:newValue?.id,
                          totalWeight
                        }
                        postRequest('/getshippingcharges',params, (response) => {
                          if(response?.data?.status === "success")
                            {
                              const shippingCharges = parseFloat(response?.data?.data)
                              const totalAmount = parseFloat(subtotal) + shippingCharges
                              setShippingCharges(shippingCharges);
                              setTotalAmount(totalAmount)
                              setBtnDisabled(false)
                              localStorage.setItem('totalAmountToPay', totalAmount)
                            }
                          });

                      }}
                      id="vehmake-select"
                      options={citieslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          label="Select city"
                          variant="outlined"
                          error={touched.city && errors.city}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                        </Grid>
                    <Grid item sm={6} sx={{ width: { xs: '100%' } }} >
                      <JumboTextField
                        fullWidth
                        label="Address"
                        name='address'
                        type='text'
                      />

                    </Grid>

                  </Grid>

                </Grid>
                <Grid item xs={6} md={5} p={1}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }}>
                      <TableHead style={{ fontWeight: 'bolder' }}>
                        <TableRow >
                          <TableCell></TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {cartItems.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={`${imageurl}${item.picture}`} alt={item.title} height="40"
                                  style={{ borderRadius: "50%", width: '40px' }} />

                              </TableCell>
                              <TableCell > {item.title}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>${item.price*item.quantity}</TableCell>
                            </TableRow>

                          </>
                        ))}


                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Div sx={{ mt: 1,  pr: 2 }}>

                  <TableContainer>
      <Table aria-label="simple table">

        <TableBody>
        <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Sub Total
              </TableCell>
              <TableCell align="right">${subtotal}</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Shipping Charges
              </TableCell>
              <TableCell align="right">${shippingCharges}</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Total Amount
              </TableCell>
              <TableCell align="right">${totalAmount}</TableCell>
            </TableRow>


        </TableBody>
      </Table>
    </TableContainer>
                  </Div>

                  <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                            disabled={btnDisabled}
                                        >Proceed to payment</LoadingButton>
                  </Div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>

    </>
  )
}

export default Checkout