import React, { useContext, useEffect, useState } from "react";
import {Grid, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import dayjs, { Dayjs } from 'dayjs';
import utc from "dayjs/plugin/utc"; // Import the utc plugin
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; // Import the English locale for accurate timezone conversion
import { useTranslation } from "react-i18next";
import FlipCountdown from '@rumess/react-flip-countdown';

dayjs.extend(utc); // Enable the utc plugin
dayjs.extend(timezone);

const CounrDownTimer2 = () => {
  const {t} = useTranslation();
  const { loginUserData, loading } = useContext(CustomProvider);
  const [countdownSize, setCountdownSize] = useState('medium'); // Initial size



// Calculate the Unix timestamp for the next upcoming Monday at 12:00 AM

let nextMonday = dayjs().tz("America/New_York");
if (nextMonday.day() === 0) { // If it's Sunday, set to next day
  nextMonday = nextMonday.add(1, 'day');
} else if (nextMonday.day() >= 1) { // If it's past Monday, set to next Monday
  nextMonday = nextMonday.add(8 - nextMonday.day(), 'days');
}
nextMonday = nextMonday.hour(0).minute(0).second(0);
const unixTimestamp = nextMonday.unix();
const formattedUnixTimestamp = dayjs.unix(unixTimestamp).format('YYYY-MM-DD HH:mm:ss');


useEffect(() => {
    // Function to update size based on screen width
    const updateSize = () => {
        if (window.innerWidth <= 768) {
            console.log('large')
            setCountdownSize('large'); // Set size to small for screens <= 768px width
        } else {
            console.log('large')
            setCountdownSize('medium'); // Set size to large for screens > 768px width
        }
    };

    // Initial size update
    updateSize();

    // Event listener to update size on window resize
    window.addEventListener('resize', updateSize);

    // Clean-up
    return () => {
        window.removeEventListener('resize', updateSize);
    };
}, []); // Empty dependency array ensures the effect runs only once


if (loading) {
    return "";
  }
  return (
    <JumboCardQuick
    sx={{ textAlign: "center", color: "common.white" }}
    >
      <Typography variant={"h2"} sx={{fontSize:20}} color={'white'}>{t("pages.title.NextMatchingBonusCalculation")}</Typography>

      <Grid container alignItems='center' justifyContent='center' spacing={2}>
        <Grid item xs={12}>

        <FlipCountdown
                theme='dark'
                size={countdownSize}
                hideYear
                hideMonth
                dayTitle={t("pages.title.Days")}
                hourTitle={t("pages.title.Hours")}
                minuteTitle={t("pages.title.Minutes")}
                secondTitle={t("pages.title.Seconds")}
                endAt={formattedUnixTimestamp} // Date/Time
            />


        </Grid>
      </Grid>


    </JumboCardQuick>
  );
};

export default CounrDownTimer2;
