import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Typography,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { useState } from "react";
import { postRequest, updateProfilePicture } from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";
import ContentHeader from "app/layouts/shared/headers/ContentHeader";

const EcommerceHeader = ({collection,collectionName}) => {
  const {t} = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
const [collectionDetails, setCollectionDetails] = useState('')


  const GetCollection = (nextId = "") => {
    let params = {
      collectionSlug:collection
    };

    postRequest(
      "/getcollectiondetails",
      params,
      async (response) => {
          const newNftCollection = response?.data?.data;
          await setCollectionDetails(newNftCollection)
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  
  useEffect(() => {
    GetCollection();
  }, []);
console.log('collectionDetails.thumbnail', collectionDetails.thumbnail)
  return (
    <ContentHeader
      avatar={
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}

          sx={{
            mr: 2,
            "& .MuiBadge-badge": {
              height: 35,
              width: 35,
              minWidth: 16,
              overflow: "hidden",
              border: 1,
              borderColor: "common.white",
              color: "common.white",
              bgcolor: "primary.main",
              cursor: "pointer",
              right: 12,
              bottom: 12,
            },
          }}
        >
          <Avatar
            sx={{ width: 100, height: 100 }}
            alt={collectionName}
            src={collectionDetails.thumbnail}
          />
        </Badge>
      }
      title={collectionName}

      sx={{
        position: "relative",
        zIndex: 1,

        "& .MuiCardHeader-action": {
          alignSelf: "center",
        },
      }}
    />
  );
};

export default EcommerceHeader;
