import React, { useContext, useState } from "react";
import { Button, Dialog, DialogContent, IconButton, Typography, Zoom } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Close, Info } from "@mui/icons-material";
import ResidualPayout from "app/pages/payout/ResidualPayout";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backdropFilter: 'blur(8px)',
    '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal - 1,
  },
}));


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


const AccumulativeBonus = () => {
  const { t } = useTranslation();
  const { loginUserData, loading } = useContext(CustomProvider);
  const [btnLoading, setbtnLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const classes = useStyles();

  const handlePayout = () =>{
    setbtnLoading(true)
    setTimeout(() => {
    setOpen(true)
    }, 2000)
  }

  const handleClose = () => {
          setbtnLoading(false)
          setOpen(false);
  };

  return (
    <JumboCardQuick
      sx={{ textAlign: "center", color: "common.white" }}
    >

{open && <div className={classes.backdropBlur}></div>}
        <Dialog open={open}  onClose={handleClose}>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
          <DialogContent>
            <ResidualPayout />
          </DialogContent>


        </Dialog>


      <Typography variant={"h4"} color={'white'}>{t("widgets.title.accumulativebonus")}</Typography>
      <Div
        sx={{ margin: "auto", minWidth: 0, justifyContent: "center", mb: 0,height:'102px', width:'102px', borderRadius:'50%', background:'#3bd2a2', pt:4, boxShadow:'1px 0px 15px #3bd2a2', overflow:'hidden' }}
      >

        <img
        className="animate-scale"
          src={`${ASSET_IMAGES}/accumulative.png`}
          alt={``}
          width={'80%'}
        />
      

      </Div>

      <Typography variant={"body1"} color={"common.white"} sx={{ mt: 1,mb: 1, fontSize:'20px' }}>
        {`${(loginUserData?.loginUserData || 0)} Matic`}
      </Typography>


      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit" sx={{fontWeight:'bold'}}>{t("widgets.title.accumulativeRbonus")}</Typography>
            <div dangerouslySetInnerHTML={{ __html: t("widgets.title.accumulativedetails", { trans: 'html' }) }}></div>
          </React.Fragment>
        }
      >
        <Button 
        variant="contained" size="small" sx={{borderRadius:'50px'}} color="success" startIcon={<Info />} >
          {t("pages.title.Details")}
        </Button>
    </HtmlTooltip>
{/* 
    <Chip
      sx={{fontSize:25}}
      label={`${(loginUserData?.current_balance || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
      color={'success'}
    /> */}


    </JumboCardQuick>
  );
};

export default AccumulativeBonus;
