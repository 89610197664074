import React, { useContext, useState } from 'react';
import {Alert, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { CheckCircle, ContentCopyOutlined, Redo, Undo, WarningAmber } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

  


const EarnRewardCard = () => {

    const [copiedLeft, setCopiedLeft] = useState(false);
    const [copiedRight, setCopiedRight] = useState(false);
    const { loginUserData, loading, setalertData } = useContext(CustomProvider);
    let userData = loginUserData;
    const { t } = useTranslation();

    const handleCopy = (leg) => {
        let userReferralLink
        if(leg === 'L'){
          setCopiedLeft(true);
          userReferralLink = `${loginUserData?.referrallink}/${loginUserData?.randomcode}/L1232738`
        }else{
          setCopiedRight(true);
          userReferralLink = `${loginUserData?.referrallink}/${loginUserData?.randomcode}/R12231333`
        }
        navigator.clipboard.writeText(userReferralLink);
        setalertData({
          show: true,
          message: "Referral link copied to clipboard",
          variant: "success",
        });
        setTimeout(() => {
          setCopiedLeft(false);
          setCopiedRight(false);
        }, 2000);
      };

    return (
        <JumboCardFeatured
            showTick
            tickSx={{bgcolor: 'warning.main'}}
            bgColor={["#222", "#027b74"]}
            direction={"column"}
            textAlign={"center"}
            imgSrc={getAssetPath(`${ASSET_IMAGES}/widgets/undraw_digital_currency_qpak.svg`, `460x218`)}
            imgSx={{height: 270}}
        >
            <Typography variant={"h5"} color={"common.white"} mb={2}>
            {t("pages.title.earnReward")}
                
            </Typography>
            {userData?.status === "approved" ? (
                <>
                <Button variant="contained" onClick={()=>handleCopy('L')} color="success" size='small' sx={{borderRadius:'50px', padding:'2px 10px', color:'#fff', fontWeight:'bold', mb:2, mr:2}} 
                    startIcon={<Undo color="success.secondary" />} endIcon={<IconButton> {copiedLeft ? ( <CheckCircle color="#fff" />) : (<ContentCopyOutlined color="warning" /> )}</IconButton>} >  {t("pages.title.LeftLink")}</Button>
                
                <Button variant="contained" onClick={()=>handleCopy('R')} color="error" size='small' sx={{borderRadius:'50px', padding:'2px 10px', color:'#fff',  fontWeight:'bold', mb:2}} 
                startIcon={<Redo color="success.secondary" />} endIcon={<IconButton>{copiedRight ? (<CheckCircle color="#fff" />) : (<ContentCopyOutlined color="warning" /> )}</IconButton>}> {t("pages.title.RightLink")} </Button>
                </>

                ) : (
                <Alert
                icon={<WarningAmber/>}
                variant="outlined"
                style={{ color: "yellow" }}
                >
                {t("pages.title.Pleasebuyamembershiptoactivateyourreferrallink")}
                </Alert>
                )}
        </JumboCardFeatured>
    );
};

export default EarnRewardCard;
