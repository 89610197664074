import React, { useCallback, useContext, useState } from "react";
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import { Alert, CardActions, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartTriggerButton from "./CartTriggerButton";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { ShoppingCart, ShoppingCartCheckout } from "@mui/icons-material";

const CartDropDown = () => {
  const {t} = useTranslation();
  const { cartItems,totalCartQuantity } = useContext(CustomProvider);

  const { theme } = useJumboTheme();
  const nav = useNavigate();


  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={<CartTriggerButton  />}
        disableInsideClick
      >
          <Div sx={{ width: 360, maxWidth: "100%", p:2 }}>
            {
                totalCartQuantity > 0 ? (
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }}>
                      <TableHead style={{ fontWeight: 'bolder' }}>
                        <TableRow >
                          <TableCell></TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Amount</TableCell>

                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {cartItems.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={`${'https://mptestbackend.m5networkhub.com/uploads/products/'}${item.picture}`} alt={item.title} height="40"
                                  style={{ borderRadius: "50%", width: '40px' }} />

                              </TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>${item.price*item.quantity}</TableCell>

                            </TableRow>

                          </>
                        ))}


                      </TableBody>
                    </Table>
                  </TableContainer>

                )
                :
                (
                    <Alert severity="warning" textAlign={'center'}>Your cart is empty</Alert>
                )
            }

            <Divider />
            <CardActions sx={{ justifyContent: "center" }}>
            {
                totalCartQuantity > 0 ? (
                    <>
            <Button
                onClick={() => nav("/cart")}
                sx={{
                  textTransform: "none",
                  fontWeight: "normal",
                  "&:hover": { bgcolor: "transparent" },
                  width:'100%',
                  color:'white'
                }}
                color="primary"
                size={"medium"}
                variant="contained"
                endIcon={<ShoppingCart />}
                disableRipple
              >
                View Cart
              </Button>
              <Button
                onClick={() => nav("/checkout")}
                sx={{
                  textTransform: "none",
                  fontWeight: "normal",
                  "&:hover": { bgcolor: "transparent" },
                  width:'100%',
                  color:'white'


                }}
                color="warning"
                size={"medium"}
                variant="contained"
                endIcon={<ShoppingCartCheckout />}
                disableRipple
              >
                Checkout
              </Button>
              </>
                )
                :
                null
            }
            </CardActions>
          </Div>
        
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default CartDropDown;
