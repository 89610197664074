import React from 'react';
import CardHeader from "@mui/material/CardHeader";
import { useTranslation } from 'react-i18next';

const MessagesHeader = ({settingMenuCallback}) => {
    const {t} = useTranslation();
    return (
        <CardHeader
            title={t("pages.title.Messages")}
            sx={{
                '& .MuiCardHeader-action': {
                    alignSelf: 'center',
                }
            }}
        />
    );
};

export default MessagesHeader;
