import React from "react";
import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useState } from "react";
import { useBasicAuth } from "app/auth-providers/BasicAuth/hooks";
import { useEffect } from "react";
import {
  validateEmailToken,
  verifyemailaccount,
} from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import ImageWithFadeInWave from "app/pages/components/mui/images/ImageWithFadeInWave";
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";

const validationSchema = yup.object({
  username: yup.string("Enter your username").required("Username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = ({ disableSmLogin }) => {
  const { t } = useTranslation();
  const [randomQuote, setRandomQuote] = useState("");
  const { signIn, manualSignIn } = useBasicAuth();
  const navigate = useNavigate();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [validToken, setValidToken] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const [serverError, setServerError] = React.useState(null);
  const { token, email } = useParams();
  const quotes = [
    `${t("pages.title.quote1")}`,
    `${t("pages.title.quote2")}`,
    `${t("pages.title.quote3")}`,
    `${t("pages.title.quote4")}`,
    `${t("pages.title.quote5")}`,
    `${t("pages.title.quote6")}`,
    `${t("pages.title.quote7")}`,
    `${t("pages.title.quote8")}`,
    `${t("pages.title.quote9")}`,
    `${t("pages.title.quote10")}`,
    `${t("pages.title.quote11")}`,
    `${t("pages.title.quote12")}`,
    `${t("pages.title.quote13")}`,
    `${t("pages.title.quote14")}`,
    `${t("pages.title.quote15")}`,
    `${t("pages.title.quote16")}`,
    `${t("pages.title.quote17")}`,
    `${t("pages.title.quote18")}`,
    `${t("pages.title.quote19")}`,
    `${t("pages.title.quote20")}`,

  ];

  useEffect(() => {
    const getRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      const quote = quotes[randomIndex];
      setRandomQuote(quote);
    };

    getRandomQuote();
  }, []);

  const onSignIn = (username, password, setSubmitting) => {
    const ipaddress = "127.0.0.1";
    
    signIn(
      username,
      password,
      ipaddress,
      (response) => {
        if (response?.status === "error") {
            if (response?.message === 'Invalid login details') {
                setalertData({
                    show: true,
                    message: `${t("pages.title.Invalidlogin")}`,
                    variant: "error",
                  });
            } else if (response?.message === "verify your account") {
                setalertData({
                    show: true,
                    message: `${t("pages.title.verifyyouraccount")}`,
                    variant: "error",
                  });
            }
          setSubmitting(false);
          setIsLoading(false);
        } else if (response?.status === "success") {
          setIsLoading(false);
          const code = sessionStorage.getItem("authorization")
          console.log('code',code)
          if (code) {
            console.log('elevated authorization')
            navigate(`/elevated-auth/${code}`);
          }else{
            navigate("/dashboard");
          }
        } else {
          setIsLoading(false);
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  function checkToken(token, email) {
    if (token && email) {
      if (email === "manual") {
        manualSignIn(
          token,
          (response) => {
            if (response?.status === "error") {
              setalertData({
                show: true,
                message: response?.message,
                variant: "error",
              });
              setIsLoading(false);
            } else if (response?.status === "success") {
              setIsLoading(false);
              navigate("/dashboard");
            } else {
              setIsLoading(false);
              setalertData({
                show: true,
                message: "Something went wrong please try again later",
                variant: "error",
              });
            }
          },
          (error) => {
            console.log(error?.response?.data);
          }
        );
      } else {
        verifyemailaccount(
          token,
          email,
          (response) => {
            if (response?.data?.status === "success") {
              setValidToken(true);
              setIsLoading(false);
            } else {
              setValidToken(false);
              setIsLoading(false);
            }
          },
          (error) => {
            console.log(error?.response?.data);
          }
        );
      }
    }
  }
  useEffect(() => {
    checkToken(token, email);
  }, []);

  if (email === "manual") {
    return (
    <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />
    </Div>

    );
  }

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: {xs:'100px auto', sm:'auto'},
        p: {xs:0, sm:4},
      }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            height:{xs:'150px', sm:'unset'},
            flex:{xs:'0 1 150px', sm:'0 1 300px'},
            position: "relative",
            background: `#000 url(${getAssetPath(
              `https://i.seadn.io/gae/HEQry0KOE14Xfa6g22oGGdW12Pce2RimT1Bify02Czr26iKRuSoUTpOW0H4sr4SGFuHDHieK8YKKQXh74bOLks3nlg8o9zypfjF7?auto=format&dpr=1&w=540`,
              "640x428"
            )}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: "rgba(37, 37, 39, 0.85)",
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
              sx={{display: { xs: 'none', sm: 'block' }}}
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                {`${t("pages.title.login")}`}
              </Typography>
              <Typography variant={""} mb={2} sx={{display: { xs: 'none', sm: 'block' }}}>
                <h4>{`${t("pages.title.quoteoftheday")}`}</h4>
                <p>{randomQuote}</p>
              </Typography>
              <Typography variant={"body1"} sx={{display: { xs: 'none', sm: 'block' }}}>
                <MyLink
                  style={{ color: "yellow" }}
                  to={"/forget-password"}
                  color={"inherit"}
                  underline={"none"}
                >
                  {`${t("pages.title.forgetpassword")}`}
                </MyLink>
              </Typography>
            </Div>

            <Div sx={{ mt:{xs:'-46px', sm:"auto"} , textAlign: "center" }}>
              <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
               <img src={`${ASSET_IMAGES}/logo-white.png`} className="pulse-button" style={{width:'150px'}} alt="Logo" />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          {validToken === true ? (
            <Alert severity="success">
              {`${t("pages.title.verifiedtext")}`}{" "}
            </Alert>
          ) : validToken === false ? (
            <Alert severity="error">{`${t("pages.title.invalidlink")}`}</Alert>
          ) : null}

          <Formik
            validateOnChange={true}
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.username.toLowerCase(), data.password, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {serverError?.message && (
                  <Alert severity="error">{serverError.message}</Alert>
                )}
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField fullWidth name="username" label={`${t("pages.title.Username")}`} />
                </Div>
                <Div sx={{ mt: 1, mb: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label={`${t("pages.title.password")}`}
                    type="password"
                  />
                </Div>
                {/* <Div sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                </Div> */}
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                  sx={{mt:2}}
                >
                  {`${t("pages.title.login")}`}
                </LoadingButton>
                <Div sx={{ mt: 6, pl:10, pr:10 }}>
                <LocalizationOptions />
                  {/* <Typography variant="p">
                    Don't have an account?{" "}
                    <MyLink
                      style={{ color: "#ab90f2" }}
                      component="Link"
                      to="/signup"
                    >
                      Signup
                    </MyLink>
                  </Typography> */}
                </Div>
                {disableSmLogin && (
                  <React.Fragment>
                    <Typography variant={"body1"} mb={2}>
                      Or sign in with
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      mb={1}
                    >
                      <IconButton
                        sx={{
                          bgcolor: "#385196",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#385196",
                          },
                        }}
                        aria-label="Facebook"
                      >
                        <Facebook fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#00a8ff",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#00a8ff",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Twitter fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#23272b",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#23272b",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Google fontSize="small" />
                      </IconButton>
                    </Stack>
                  </React.Fragment>
                )}
              </Form>
            )}
          </Formik>
          <Typography variant={"body1"} sx={{display: { xs: 'block', sm: 'none' }, textAlign:'center', mt:2}}>
                <MyLink
                  style={{ color: "yellow" }}
                  to={"/forget-password"}
                  color={"inherit"}
                  underline={"none"}
                >
                  {`${t("pages.title.forgetpassword")}`}
                </MyLink>
              </Typography>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login;
