import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { countries } from "../../components/mui/AutoCompletes/data";
import {
  postRequest,
  registerUser,
} from "../../../../backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { data } from "app/shared/metrics/SalesStatistics/data";
import "../login/login.css";
import { useTranslation } from "react-i18next";
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import { Handshake } from "@mui/icons-material";



const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backdropFilter: 'blur(8px)',
    '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal - 1,
  },
}));


const validationSchema = yup.object({
  firstname: yup.string("Enter first name").required("first name is required"),
  username: yup
    .string("Enter username")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Username must only contain alphabets and numbers"
    )
    .matches(/^\S*$/, "Username must not contain spaces")
    .min(6, "Username must be at least 6 characters long")
    .required("Username is required"),
  lastname: yup.string("Enter last name").required("last name is required"),
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
    mobile: yup
    .string("Enter mobile number")
    .required("mobile number is required"),
    gender: yup
    .string("Select a gender")
    .required("Gender is required"),
  address: yup.string("Enter your address").required("address is required"),
  mobilecountry: yup.mixed().required("select code"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()+^])[A-Za-z\d@$!%*?&()+^]+$/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character allowed charecters are @$!%*?&()+^"
    ),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const Signup = () => {
  const {t} = useTranslation();
  const { referralid, pid ,referralside } = useParams();
  const [referrer, setReferrer] = useState('')
  const [agreed, setAgreed] = useState('')
  const [open, setOpen] = useState('')
  const classes = useStyles();

  const [selectedCountry, setSelectedCountry] = useState(
    countries.find(
      (country) => country.label.toLowerCase() === "United States".toLowerCase()
    )
  );
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();
  const referralSide = referralside === 'L1232738' ? 'L' : referralside === 'R12231333' ? 'R' : 'L'
  const initialstates = {
    sponsorid: referralid || "JKKJ62GJHF",
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    confirm_email: "",
    mobile: "",
    company: "",
    address: "",
    mobilecountry: selectedCountry || null,
    language: null,
    password: "",
    confirmpassword: "",
    birthday: null,
  };
  const handleSubmit = (data, setSubmitting) => {
    let params = {
      referralSide,
      pid:pid || data.sponsorid,
      sponsorid: data.sponsorid,
      username: data.username.toLowerCase(),
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: `+${selectedCountry.phone}${data.mobile}`,
      address: data.address,
      password: data.password,
      zipcode: data.zipcode,
      birthdate: data.birthdate,
      gender: data.gender
    }


    registerUser(
      params,
      async (response) => {
        if (response?.data?.status === "error") {
          if (response?.data?.message === 'username taken') {
            await setalertData({
              show: true,
              message: `${t("pages.title.usernametaken")}`,
              variant: "error",
            });
          } else if (response?.data?.message === 'email exists') {
            await setalertData({
              show: true,
              message: `${t("pages.title.emailexists")}`,
              variant: "error",
            });
          } else if (response?.data?.message === 'Invalid sponsor name') {
            await setalertData({
              show: true,
              message: `${t("pages.title.Invalidsponsorname")}`,
              variant: "error",
            });
          }
        } else if (response?.data?.status === "success") {
          console.log('successsuccesssuccess')
          if (response?.data?.message === "email not sent") {
            await setalertData({
              show: true,
              message: `${t("pages.title.emailnotsent")}`,
              variant: "warning",
            });
          } else {
            await setalertData({
              show: true,
              message: `${t("pages.title.accountcreated")}`,
              variant: "success",
            });
            await navigate("/login");
          }
          await setSubmitting(false);

        } else {
          await setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          await setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
}


const ReferralData =()=>{
  postRequest('/singleuserdata',{"randomcode":referralid},(response) => {
    if(response?.data?.status === "success") {
        setReferrer(response?.data)
    }
    }, (error) => {
        console.log(error?.response?.data); 
    })
}
  
useEffect(()=>{
  ReferralData();
},[])


const handleAgree = () => {
  setAgreed(true);
  setOpen(false);
};

const handleClose = () => {
  if (!agreed) {
    // Do not close if the user has not agreed
    return;
  }
        setOpen(false);

};

const handleOpen = () => {
  console.log('opeennn')
        setOpen(true);
};



  return (
    <Div
      sx={{
        width: 1000,
        maxWidth: "100%",
        margin: "auto",
        p: 2,
        "@media screen and (max-width: 500px)": {
          paddingBottom: "200px", // Adjust this for your responsive design
        },
      }}
    >
{/* 
{open && <div className={classes.backdropBlur}></div>}
        <Dialog open={open}  onClose={handleClose}>
          <DialogTitle>{t("termsdialog.title")}</DialogTitle>
          <DialogContent className="termslist">
            <ol>
            <li>
                <h4>Fast Start Bonus</h4>
                <ul>
                  <li><b>*Penalty for Instant Withdrawals*:</b> If a user opts for an instant withdrawal of their reward within 24 hours, a penalty of 10% will be deducted from the reward amount.</li>
                </ul>
              </li>

              <li>
                <h4>Know Your Customer (KYC)</h4>
                <ul>
                  <li><b>*Mandatory KYC Completion*:</b> Users must complete the KYC process before they are permitted to make any withdrawals from their account.</li>
                </ul>
              </li>


              <li>
                <h4>Inactive Users</h4>
                <ul>
                  <li><b>*Account Deletion for Inactivity*:</b> Accounts that show no activity for a continuous period of 24 hours will be deleted from the system.</li>
                </ul>
              </li>

              <li>
                <h4>Return NFT Without Notice</h4>
                <ul>
                  <li><b>*Account Review for Unauthorized NFT Returns*:</b> If an NFT is returned without prior notice, the account associated with the return will undergo a review and may face potential action based on the findings.</li>
                </ul>
              </li>


              <li>
                <h4>Shared IP Address/Device Violations</h4>
                <ul>
                  <li><b>*First Violation*:</b> The account will be locked for 7 days.</li>
                  <li><b>*Second Violation*:</b> The account will be locked for 30 days.</li>
                  <li><b>*Third Violation*:</b> The account will be permanently closed.</li>
                </ul>
              </li>
            </ol> */}

            {/* <div dangerouslySetInnerHTML={{ __html: t("dialog.content", { trans: 'html' }) }}></div> */}
        {/* </DialogContent>
        <DialogActions sx={{ textAlign: 'center' }}>
          <LoadingButton onClick={handleAgree}  color="primary" variant="contained" size="small" endIcon={<Handshake />} >
            I Agree
          </LoadingButton>
        </DialogActions>=

        </Dialog> */}
        
        {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }
      <center>
        <Card
          sx={{
            display: "flex",
            minWidth: 0,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <CardContent
            sx={{
              flex: "0 1 300px",
              position: "relative",
              background: `#0267a0 url(${getAssetPath(
                `${ASSET_IMAGES}/widgets/signup-page.jpg`,
                "640x428"
              )}) no-repeat center`,
              backgroundSize: "cover",

              "&::after": {
                display: "inline-block",
                position: "absolute",
                content: `''`,
                inset: 0,
                backgroundColor: "rgb(27 32 62 / 85%)",
              },
            }}
          >
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                flex: 1,
                flexDirection: "column",
                color: "common.white",
                position: "relative",
                zIndex: 1,
                height: "100%",
              }}
            >
              <Div sx={{ mb: 2 }}>

                <Typography
                  variant={"h3"}
                  color={"inherit"}
                  fontWeight={500}
                  mb={3}
                >
                  {t("pages.title.signup")}
                </Typography>


                <Typography variant="p">{t("pages.title.alreadyaccount")} <MyLink
                    style={{ color: "yellow" }}
                    component="Link"
                    to="/login"
                  > {t("pages.title.login")}</MyLink>
                </Typography>

                <Typography
                  variant="h3"
                  color={"inherit"}
                  mt={6}
                  sx={{ textAlign: "left" }}
                >
                  {t("pages.title.sponsoredby")}
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ textAlign: "left" }}
                  color={"yellow"}
                >
                  {t("pages.title.Referrer")} : {`${referralid ? referrer?.data?.username || '' : 'Admin'}`}</Typography>

                  <Typography
                  variant="h5"
                  sx={{ textAlign: "left" }}
                  color={"yellow"}
                >
                  {t("pages.title.Position")} : {referralSide === 'L' ? 'Left' : referralSide === 'R' ?  'Right' : 'Left'}</Typography>

              </Div>

              <Div sx={{pl:10, pr:10, mb:1 }}>
                  <LocalizationOptions />
                </Div>
                
              <Div sx={{ mt: "auto", textAlign: "center" }}>
                <Link  underline="none" sx={{ display: "inline-flex" }}>
                  <img
                    src={`${ASSET_IMAGES}/logo-white.png`}
                    style={{ width: "150px" }}
                    alt="GDSG"
                  />
                </Link>
              </Div>
            </Div>
          </CardContent>

          <CardContent
            sx={{
              flex: 1,
              pl: 1,
              pr: 1,
              pt: 4,
              pb: 4,
            }}
          >
            <Formik
              validateOnChange={false}
              initialValues={initialstates}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                // console.log('ddd', data)
                handleSubmit(data, setSubmitting);
              }}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label={t("pages.title.Username")}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label={t("pages.title.Email")}
                        type="email"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.FirstName")}
                        name="firstname"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.LastName")}
                        name="lastname"
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
              <FormLabel id="gender-radio-buttons-group-label" sx={{ mb: 1 }}>
                Gender
              </FormLabel>
              <Field as={RadioGroup}
                row
                aria-labelledby="gender-radio-buttons-group-label"
                name="gender"
                sx={{ background: '#3C475F', marginLeft: '0px', padding: '5px', borderRadius: '5px' }}
                error={touched.gender && errors.gender}
                helperText={touched.gender && errors.gender}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </Grid>
                </Grid>
              </Field>
                    </FormControl>
                    </Grid>

                    <Grid item xs={3} className='mobilecountry'>
                      <Autocomplete
                        sx={{ width: "100%", pr:0 }}
                        component={TextField}
                        value={selectedCountry}
                        onChange={(event, newValue) => {
                          setSelectedCountry(newValue);
                          setFieldValue("mobilecountry", newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          // Clear the selected country when the user types
                          if (newValue === null) {
                            setSelectedCountry(null);
                          }
                        }}
                        id="mobilecountry-select-demo"
                        options={countries}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue.toLowerCase();
                          return options.filter((option) =>
                            option.label.toLowerCase().startsWith(inputValue)
                          );
                        }}
                        autoHighlight
                        getOptionLabel={(option) => `+${option?.phone}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 1, flexShrink: 0, ml:0 } }}
                            {...props}
                            style={{padding:'5px'}}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <JumboTextField
                          style={{paddingRight:0}}
                            name="mobilecountry"
                            fullWidth
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: selectedCountry ? (
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                              ) : null,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.Phone")}
                        name="mobile"
                        type="tel"
                      />
                    </Grid>


                    <Grid item xs={12} sm={12}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.MailingAddress")}
                        name="address"
                      />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.password")}
                        type="password"
                        name="password"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.confirmpassword")}
                        name="confirmpassword"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={<Checkbox checked />}
                        label={
                          t("pages.title.hereby")
                        }
                        // label=" I hereby confirm that I am aged of over 16 and agree to terms and conditions."
                      />
                      <Link onClick={()=>handleOpen()} underline="none" sx={{ display: "inline-flex", cursor:'pointer' }}>
                        {t("pages.title.termsandconditions")}
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.RegisterNow")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </center>
    </Div>
  );
}

export default Signup
