import React, { useContext, useEffect, useState } from "react";
import {
  enUS,
  jaJP,
  koKR,
  esES,
  zhCN,
  arSA
} from "@mui/material/locale";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";


const languages = [
  {
    label: "English",
    locale: "en-US",
    muiLocale: enUS,
    code:'us',
    direction:'ltr'
  },

  {
    label: "Japanese",
    locale: "ja-JP",
    muiLocale: jaJP,
    code:'jp',
    direction:'ltr'
  },
  {
    label: "Korean",
    locale: "ko-KR",
    muiLocale: koKR,
    code:'kr',
    direction:'ltr'
  },
  {
    label: "Spanish",
    locale: "es-ES",
    muiLocale: esES,
    code:'es',
    direction:'ltr'
  },
  {
    label: "Chinese",
    locale: "zh-CN",
    muiLocale: zhCN,
    code:'cn',
    direction:'ltr'
  },
  {
    label: "Arabic (SA)",
    locale: "ar-SA",
    muiLocale: arSA,
    code:'sa',
    direction:'rtl'
  }
];

const LocalizationOptions = () => {
  const { i18n } = useTranslation();
  const { theme, setMuiLocale, setTheme } = useJumboTheme();
  const { localLoading, setlocalLoading } = useContext(CustomProvider);
  const [activeLocale, setActiveLocale] = React.useState(() => {
    const storedLocale = localStorage.getItem("selectedLocale");
    return storedLocale ? JSON.parse(storedLocale) : languages[0];
  });

  useEffect(async () => {
    const storedLocale = localStorage.getItem("selectedLocale");
    if (storedLocale) {
      const parsedLocale = JSON.parse(storedLocale);
        await changeDirection(parsedLocale?.direction)
        await i18n.changeLanguage(parsedLocale.locale).then(async () => {
        await setMuiLocale(parsedLocale.muiLocale);
      });
      await setlocalLoading(false)
    }
  }, []);

  const changeDirection = async (dir) => {
    await setTheme({ direction: dir === 'rtl' ? "rtl" : "ltr" });
    await document
      .querySelector("body")
      .setAttribute("dir", dir === 'rtl' ? "rtl" : "ltr");
  };

  const handleChange = async (event) => {
    await setlocalLoading(true)

    const localeIndex = await  languages.findIndex(
      (language) => language.locale === event.target.value
    );
    if (localeIndex !== -1) {
      const selectedLocale = languages[localeIndex];
      await changeDirection(selectedLocale?.direction)
      await  i18n.changeLanguage(selectedLocale.locale).then(async () => {
      await setActiveLocale(selectedLocale);
      await setMuiLocale(selectedLocale.muiLocale);
        localStorage.setItem("selectedLocale", JSON.stringify(selectedLocale));
      });
      await setlocalLoading(false)
    }
  };

if(localLoading)
{
  return ""
}

  return (
    <FormControl fullWidth id="customizer-select-locale" >
      <Select
        size={"small"}
        labelId="select-locale"
        
        value={activeLocale.locale || null}
        label="Language"
        onChange={handleChange}
      >
        {languages.map((language) => (
          <MenuItem key={language.locale} value={language.locale}>
          
                          <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${language.code}.png`}
                              srcSet={`https://flagcdn.com/w40/${language.code}.png 2x`}
                              alt=""
                              style={{marginRight:'10px'}}
                            />
                            
            {language.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocalizationOptions;
