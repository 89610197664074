import React, { useState, useEffect, useRef, useContext } from 'react'
import { Container, Grid, TextField, Button, Typography, Paper, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { postRequest } from 'backendServices/ApiCalls';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const ChatBox = ({selectedMessage,setLoadingRows,setMessagesData,messages}) => {
    const [Chatmessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);

    const lastMessageRef = useRef(null);
    const { loginUserData } = useContext(CustomProvider);

    const sendMessage =()=>{
        if (!message.trim()) return;
        setSending(true)

        let params = {
            randomcode:selectedMessage?.randomcode,
            message:message.replace(/\n/g, '<br />'),
            title:selectedMessage?.title,
            username:selectedMessage?.sender
        }
      postRequest('/sendmessage',params,(response) => {
        setChatMessages([...Chatmessages, response.data?.data]);
        setMessage('');
        setSending(false)

        }, (error) => {
              console.log(error?.response?.data); 
          })
      }

useEffect(async ()=>{
  await setLoading(true)
    await postRequest('/getChat',{msgCode:selectedMessage?.randomcode},async (response) => {
        await setChatMessages(response?.data?.data)
        await setLoadingRows(prevLoadingRows => ({
            ...prevLoadingRows,
            [selectedMessage.id]: false,
          }));
          const updatedMessages = messages.map((message) =>
          message.id === selectedMessage.id ? { ...message, mstatus: "seen" } : message
        );
        await setMessagesData(updatedMessages);
  await setLoading(false)

    }, (error) => {
        console.log(error?.response?.data); 
    })
},[])

useEffect(() => {
  // Scroll to last message when Chatmessages update
  if (lastMessageRef.current) {
    lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [Chatmessages]);


const handleKeyPress = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault(); // Prevent default behavior of submitting the form
    sendMessage();
  }
  };

const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
  };


  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Sender: Admin</Typography>
          <Typography variant="h4">{selectedMessage?.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px', maxHeight: '50vh', overflowY: 'auto' }}>
            {Chatmessages.map((message, index) => (
              <div key={index} ref={index === Chatmessages.length - 1 ? lastMessageRef : null} style={{ textAlign: message.sender == loginUserData?.userid ? 'right' : 'left' }}>

            <div style={{ wordWrap: 'break-word' }}>
                  <Typography variant="body1" sx={{ display: 'inline-block',maxWidth: ['80%', '50%'], wordWrap: 'break-word', padding: '2px 8px', backgroundColor: message.sender == loginUserData?.userid ? '#DCF8C6' : '#E9EBED', borderRadius: '8px', color:'#000',textAlign:'left' }}>

                   <Typography sx={{fontSize:'11px', fontWeight:'bold', marginBottom:'10px'}}>({message.sender == loginUserData?.userid ? 'You' : 'Admin'})</Typography>
                    <div dangerouslySetInnerHTML={{ __html: message.message }} />

                  </Typography>
                </div>

                <Typography variant="caption" style={{ display: 'block', marginBottom: '4px' }}>
                  {formatDateTime(message.createdat)} 
                </Typography>
              </div>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12}>
            {
                selectedMessage?.receiver !== "all"
                ?
                (
                    <TextField 
                    label="Type a message..." 
                    variant="outlined" 
                    value={message} 
                    onChange={e => setMessage(e.target.value)} 
                    fullWidth
                    multiline
                    rows={2}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={sendMessage}>
                        {
                          sending ? (
                            <CircularProgress size={24} />
                          )
                          :
                          (
                            <SendIcon color='success' />
                          )
                        }
                        
                      </IconButton>
                      ),
                    }}
                  />
                )
                :
                null
            }

        </Grid>
      </Grid>
    </Container>
  )
}

export default ChatBox