import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid  } from '@mui/material';
import React, { useContext, useState } from 'react'
import KycPopup from './KycPopup';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Fingerprint } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
    backdropBlur: {
      backdropFilter: 'blur(8px)',
      '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zIndex.modal - 1,
    },
  }))

const KycBox = ({setalertData}) => {
  const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const { loginUserData } = useContext(CustomProvider);
    const classes = useStyles();

    const kycstatus = loginUserData?.settingsdata?.find(
        (sdata) => sdata.keyname === "kyc"
      );

      

  return (
    <>
{kycstatus?.keyvalue === "On" && loginUserData?.kyc_status !== "Verified" ? (

<Alert severity='info' sx={{width:'100%'}}>
    <Grid container spacing={2}>
        <Grid item sm={8}><span>{t("pages.title.kycalerttext")}</span></Grid>
        <Grid item sm={4}>
        <Button
          variant="contained"
          size="small"
          color="warning"
          onClick={() => {
            setOpen(true);
            setLoader(true);
          }}
          disabled={loader}
          endIcon={<Fingerprint />}
          sx={{borderRadius:'50px'}}
          className='animate-scale'
        >
          {loader ? <CircularProgress size={24} /> : t("pages.title.kycalertbtntext")}
        </Button>
        </Grid>
    </Grid>

</Alert>

        ) : null}

{open && <div className={classes.backdropBlur}></div>}
            <Dialog
                open={open}
                keepMounted
                onClose={() => {
                  setOpen(false);
                  setLoader(false);
                }}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
              >
                <DialogContent sx={{ padding: 2,maxHeight:'400px' }}>
                  {loginUserData?.kyc_status === "Unverified" ? (
                    <KycPopup setOpen={setOpen} setLoader={setLoader} setalertData={setalertData} />
                  ) : loginUserData?.kyc_status === "Uploaded" ? (
                    <Alert severity="primary">
                      {t("pages.title.alreadysubmitted")}
                    </Alert>
                  ) : loginUserData?.kyc_status === "Rejected" ? (
                    <>
                      <Alert severity="error">
                      {t("pages.title.prevdocumentrejected")}
                        <br/>
                        <b>{t("pages.title.reason")}: </b>{loginUserData?.kycRejectReason}
                      </Alert>
                      <KycPopup setOpen={setOpen} setLoader={setLoader} />
                    </>
                  ) : loginUserData?.kyc_status === "verified" ? (
                    <Alert severity="success">
                      {t("pages.title.kycapproved")}
                    </Alert>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button
                    color="warning"
                    onClick={() => {
                      setOpen(false);
                      setLoader(false);
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
    </>

    )
}

export default KycBox