import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
  waveAnimation: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(0,0,0,0.05), transparent)',
    animation: 'wave 10s infinite linear', // Adjusted duration to 10 seconds
  },
  '@keyframes wave': {
    '0%': { backgroundPosition: '-100% 0' },
    '100%': { backgroundPosition: '100% 0' },
  },
}));

const ImageWithFadeInWave = ({ src, alt }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = src;
    imageLoader.onload = () => {
      setLoaded(true);
    };
  }, [src]);

  // Define spring animation for image opacity
  const imageOpacity = useSpring({
    opacity: loaded ? 1 : 0, // Fade in when loaded, fade out when not loaded
    from: { opacity: 0 }, // Start from fully transparent
  });

  return (
    <div className={classes.imageContainer}>
      <animated.img
        src={src}
        alt={alt}
        className={classes.image}
        style={{ ...imageOpacity, width: '150px' }}
      />
      {!loaded && <div className={classes.waveAnimation} />}
    </div>
  );
};

export default ImageWithFadeInWave;
