import React, { useState, useEffect } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import { Alert, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div/Div";
import { postRequest } from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";


const cryptocurrencies = ['MATIC (Polygon)'];

const ResidualPayout = ({setOpen,instagram, setInstagram,twitter, setTwitter,twitterUsername, setTwitterUsername,post, setPost}) => {
  const { t } = useTranslation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [settingsdata, setSettingsData] = useState([]);
  const userData = loginUserData;

  const fetchsettingdata = () => {
    const params = {
      keynames: "'payout_fee', 'min_payout', 'payout_flat_fee'",
    };
    postRequest("/getsettingsdata", params, (response) => {
      setSettingsData(response?.data?.data);
    });
  };

  useEffect(() => {
    fetchsettingdata();
  }, []);

  const validationSchema = yup.object({
    amount: yup
      .number("Enter investment amount")
      .required("Amount is required")
      .min(
        settingsdata?.values[1]?.keyvalue,
        `Amount must be at least ${settingsdata?.values[1]?.keyvalue}`
      )

      .max(
        loginUserData?.residualBonus || 10,
        `Your available Wallet balance is ${loginUserData?.residualBonus || 10} Matic`
      ),
    payoutaccount1: yup
      .string("Enter Withdrawal Method")
      .required("Withdrawal Method is required"),
    payoutaccount2: yup
      .string("Enter Wallet Details")
      .required("Wallet details is required"),
  });

  const onSubmitForm = (
    amount,
    payoutaccount1,
    payoutaccount2,
    password,
    setSubmitting,
    resetForm
  ) => {

    const formData = new FormData();
    formData.append("twitterfollowing", twitter);
    formData.append("instagramfollowing", instagram);
    formData.append("twitterpost", post);
    formData.append("twitterusername", twitterUsername);
    formData.append("amount", amount);
    formData.append("payoutaccount1", payoutaccount1);
    formData.append("payoutaccount2", payoutaccount2);
    formData.append("type", "payout");
    formData.append("status", "Pending");
    formData.append("details", "Request payout of " + amount + "Matic");
    formData.append("password", password);

    postRequest(
      "/payoutMatic",
      formData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: `${t("pages.title.payouterror")}`,
            variant: "error",
          });
          setSubmitting(false);
        }else if (response?.data?.status === "error_ninty_days") {
          setalertData({
            show: true,
            message: `${t("pages.title.requestWithdrawaltime")} ${response?.data?.days} ${t("pages.title.Days")}`,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          let netamount = userData?.residualBonus - amount;
          setloginUserData((prevState) => ({
            ...prevState,
            residualBonus: netamount,
          }));

          setalertData({
            show: true,
            message: `${t("pages.title.payoutsuccess")}`,
            variant: "success",
          });
          setloginUserData((prevState) => ({
            ...prevState,
          }));
          setInstagram('')
          setTwitter('')
          setTwitterUsername('')
          setPost('')
          setSubmitting(false);
          resetForm();
          setOpen(false)
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={12}>
        <JumboDemoCard
          title={t("pages.title.RequestWithdrawal")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            enableReinitialize="true"
            validateOnChange={true}
            initialValues={{
              amount: "",
              payoutaccount1: "",
              payoutaccount2: userData?.walletaddress || "",
              random: loginUserData?.walletbalance || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              onSubmitForm(
                data.amount,
                data.payoutaccount1,
                data.payoutaccount2,
                data.password,
                setSubmitting,
                resetForm
              );
            }}
          >
            {({ isSubmitting, setFieldValue,errors, values }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiTextField-root": { width: "34ch" },
                  }}
                  alignItems="center"
                >
                  <Div sx={{ mt: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="residualBonus"
                      label={t("pages.title.YourBalanceis") + userData?.residualBonus + ' Matic'}
                      type="number"
                      disabled
                    />
                  </Div>
                  <Div sx={{ mt: 3 }}>
                    <JumboTextField
                      fullWidth
                      name="amount"
                      label={t("pages.title.EnterPayoutAmount") + ' (Matic)'}
                      type="number"
                    />
                  </Div>
                  <Div sx={{ mt: 3, width:'100%' }}>
                  <InputLabel id="cryptoSelectLabel">{t("pages.title.EnterWithdrawalMethod")}</InputLabel>
                    <Select
                    labelId="cryptoSelectLabel"
                      fullWidth
                      id="cryptoSelect"
                      value={values.payoutaccount1}
                      name="payoutaccount1"
                      onChange={(e)=>setFieldValue('payoutaccount1',e.target.value)}
                      label={t("pages.title.EnterWithdrawalMethod")}
                      error={errors.payoutaccount1}
                      sx={{width:'100%'}}
                    >
                      <MenuItem value="">
                        <em>...Select...</em>
                      </MenuItem>
                      {cryptocurrencies.map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>

                  </Div>
                  
                  <Div sx={{ mt: 3, mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="payoutaccount2"
                      label={t("pages.title.EnterWalletDetails")}
                      type="text"
                      inputProps={{readOnly: true}}

                    />
                  </Div>
                  {/* <Div sx={{mt: 3,mb:2}}>
                                        <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Enter Password"
                                        type="password"
                                    /></Div> */}

                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    // loading={isSubmitting}
                  >
                    {t("pages.title.SubmitRequest")}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default ResidualPayout;
