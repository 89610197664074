import { elevatedAuth } from 'backendServices/ApiCalls';
import React, { useContext, useEffect } from 'react'
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Avatar, Button, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import { useParams } from 'react-router-dom';
import Div from '@jumbo/shared/Div';

function ElevatedAuth() {
    const { loginUserData, loading, setalertData, setloginUserData } = useContext(CustomProvider);
    const paramLocation = useParams()
    const authorize = () => {
        elevatedAuth("", async (response) => {
            // setMsg(response?.data)
            if (response?.data?.status === "success") {
                window.close();
            }
        },
            (error) => {
                console.log(error?.response?.data);
            }
        )
    }

    useEffect(() => {
        // setTimeout(() => {
        //     window.close();
        // }, 10000);
        sessionStorage.setItem("authorization", paramLocation.code)
    }, [])



    return (
        <div style={{ display: 'flex', alignItems: "center", flexDirection: 'column' }}>
            <div style={{ width: '40%' }}>
                <JumboCardQuick noWrapper>
                    <JumboContent />
                    <JumboContent
                        sx={{
                            p: theme => theme.spacing(0, 3, 3),
                            textAlign: 'center'
                        }}
                    >
                        <Avatar sx={{ width: 90, height: 90, boxShadow: 2, m: '20px auto' }}
                            src={loginUserData?.profilepictureurl} alt={loginUserData?.username} />
                        <Typography variant={"h5"}>{loginUserData?.username}</Typography>
                        <Typography variant={"h6"} color="text.secondary" mb={2}>Allow to login</Typography>
                    </JumboContent>
                    <JumboContent
                        sx={{
                            p: 3,
                            textAlign: 'center',
                            backgroundColor: theme => theme.palette.action.hover
                        }}
                    >
                        <Div sx={{textAlign: 'center'}}>

                        <Button variant="contained" onClick={authorize} color="success" size='large' sx={{ borderRadius: '50px', padding: '2px 10px', color: '#fff', fontWeight: 'bold', my: 2, mr: 2 }} >Authorize</Button>

                        <Button variant="contained" onClick={() => window.close()} color="error" size='large' sx={{ borderRadius: '50px', padding: '2px 10px', color: '#fff', fontWeight: 'bold', my: 2, mr:2 }} >&nbsp;Decline&nbsp;</Button>
                        </Div>
                    </JumboContent>
                </JumboCardQuick>
            </div>
        </div>
    )
}

export default ElevatedAuth 