import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Backdrop,
  DialogActions,
} from "@mui/material";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import Referralusers from "app/shared/widgets/ReferralUsers/ReferralUsers";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import {
  referralusers,
  lasttransactions,
  updateProfileData,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

import Div from "@jumbo/shared/Div/Div";
import { Handshake, Landslide, ShoppingCart,
} from "@mui/icons-material";
// import { ShoppingBagOutlined, ShoppingBasket, ShoppingCartCheckoutRounded } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import PortfolioBalance from "app/shared/metrics/PortfolioBalance";
import UpgradePlan from "../../../shared/widgets/UpgradePlan";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import EarnRewardCard from "app/shared/widgets/EarnRewardCard";
import CounrDownTimer2 from "app/shared/widgets/CountDownTimer.js/CountDownTimer2";
import { useMediaQuery } from '@mui/material';
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import KycBox from "app/pages/kyc/KyBox";
import { LoadingButton } from "@mui/lab";
import CoinMarketCapWidget from "./CoinMarketCapWidget";
import UserRank from "app/shared/widgets/UserRank/UserRank";
import UserWalletBalance from "app/shared/widgets/UserWalletBalance/UserWalletBalance";
import AccumulativeBonus from "app/shared/widgets/AccumulativeBonus/AccumulativeBonus";

const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backdropFilter: 'blur(8px)',
    '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal - 1,
  },
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 80,
  height: 37,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(44px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#E44A77',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 34,
    height: 34,
  },
  '& .MuiSwitch-track': {
    borderRadius: 50 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#0DBD9A' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const Crypto = () => {
  const { t } = useTranslation();
  const [lasttransactionsdata, setLastTransactionsData] = useState([]);
  const [picturelink, setPictureLink] = useState([]);
  const [referralusersdata, setReferralUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [rankMessageopen, setRankMessageOpen] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [rankAgreed, setRankAgreed] = useState(false);
  const classes = useStyles();
  const [agreeButtonEnabled, setAgreeButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const dialogContentRef = useRef(null);
  const nav = useNavigate()
  const [chipVisible, setChipVisible] = useState(true);
// Inside your component function/component class
const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [loader, setLoader] = useState(false);
  const { loginUserData, loading, setalertData,setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const promoStatus = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "popup_status"
  );
  const promoText = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "popup_detail"
  );

  console.log('user data', loginUserData)

  const Swal = useSwalWrapper(); 
  const sweetAlerts = () => {
    Swal.fire({
      html: promoText?.keyvalue, // Replace with your image URL
      confirmButtonText: "Close",
    });
  };

  
  const handleDelete = () => {
    setChipVisible(false);
  };

  const handleAgree = () => {
    setBtnLoading(true)
    updateProfileData(
      {disclosure: 1},
      (response) => {
          setloginUserData((prevState) => ({
            ...prevState,
            disclosure: 1,
          }));    
          localStorage.setItem('hasAgreed', 'true');
          setBtnLoading(false)
          setAgreed(true);
          setOpen(false);

      },
      (error) => {
        console.log(error?.response?.data);
      }
    );

  };

  const handleClose = () => {
    if (!agreed) {
      // Do not close if the user has not agreed
      return;
    }
          setOpen(false);

  };



  const handleRankAgree = () => {
    setBtnLoading(true)
    updateProfileData(
      {rankmessage: 0},
      (response) => {
          setloginUserData((prevState) => ({
            ...prevState,
            rankmessage: 0,
          }));    
          setBtnLoading(false)
          setRankAgreed(true);
          setRankMessageOpen(false);

      },
      (error) => {
        console.log(error?.response?.data);
      }
    );

  };

  const handleRankClose = () => {
    if (!rankAgreed) {
      // Do not close if the user has not agreed
      return;
    }
          setRankMessageOpen(false);

  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = dialogContentRef.current;
    // Enable the "I Agree" button when the user scrolls to the bottom
    if (scrollTop + clientHeight >= scrollHeight) {
      setAgreeButtonEnabled(true);
    } else {
      setAgreeButtonEnabled(false);
    }
  };

  useEffect(() => {
    const hasAgreed = localStorage.getItem('hasAgreed');
    if (!hasAgreed && loginUserData?.disclosure === 0) {
      setOpen(true);
    }
    if (loginUserData?.rankmessage === 1) {
      setRankMessageOpen(true)
    }
  
  }, []);

  const ReferralUsers = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      referralusers(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const TransactionData = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      lasttransactions(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const fetchData = () => {
    setLoader(true);

    // Make all API calls concurrently using Promise.all
    Promise.all([ReferralUsers(), TransactionData()])
      .then(
        ([referralUsersResponse, transactionsResponse]) => {
          setReferralUsersData(referralUsersResponse?.data?.data?.entries);
          setPictureLink(referralUsersResponse?.data?.data?.picturelink);
          setLastTransactionsData(transactionsResponse?.data?.data?.entries);
          setLoader(false);
        }
      )
      .catch((error) => {
        setLoader(false);
        console.error(error?.response?.data);
      });
  };

  useEffect(() => {
    fetchData();
    if (promoStatus?.keyvalue === "On") {
      sweetAlerts();
    }
  }, []);


  if (loading || loader) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" alt="" />

    </Div>
    );
  }


  const chipStyles = {
    position: 'fixed',
    top: 90,
    zIndex: 1000, // Adjust the z-index as needed
  };
  return (
    <Grid container spacing={2} >

      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <KycBox setalertData={setalertData}/>
      </Grid>

      {/* <Grid item xs={12} container alignItems="center" justifyContent="center">
      {chipVisible && (
        <Chip
        style={chipStyles}
          avatar={<Avatar src={userData?.profilepictureurl} alt={userData?.username} />}
          label={userData?.username}
          onDelete={handleDelete}
        />
      )}
      </Grid> */}
      <Grid item xs={12} md={12}style={{borderRadius:'10px', minHeight:'400px'}}>
        <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} >
        {isMobile ? (
          <Grid item xs={12} md={4} style={{borderRadius:'10px', minHeight:'200px', textAlign:'center'}}>
            <img src={`${ASSET_IMAGES}/web3/airdrop.png`} style={{height:'200px'}} className="airdropbox" alt="" />
          </Grid>
          ) : null}

          <Grid item xs={12} md={8} style={{borderRadius:'10px',}} className="textbox">
          <div className="text-container" style={{paddingTop:'40px', marginBottom:'20px'}}>
          {t("pages.title.airDropText")}
          </div>
            <Button variant="contained" endIcon={<Landslide />} color="primary" sx={{borderRadius:'50px', marginLeft:'10px', color:'#fff', fontWeight:'bold'}} onClick={()=>{nav('/packages')}}>{t("sidebar.menuItem.Packages")}</Button>
            <a href="http://localhost:3001/" target="_blank">
            <Button variant="contained" endIcon={<ShoppingCart />} color="primary"  sx={{borderRadius:'50px', marginLeft:'10px', color:'#fff', fontWeight:'bold'}}>{t("sidebar.menuItem.MarketPlace")}</Button>
            </a>
          </Grid>
          {!isMobile ? (
          <Grid item xs={12} md={4} style={{borderRadius:'10px', minHeight:'400px'}}>
            <img src={`${ASSET_IMAGES}/web3/airdrop.png`} style={{height:'300px'}} className="airdropbox" alt="" />
          </Grid>
          ) : null}
        </Grid>
      </Grid>


      {open && <div className={classes.backdropBlur}></div>}
        <Dialog open={open}  onClose={handleClose}>
          <DialogTitle>{t("dialog.title")}</DialogTitle>
          <DialogContent className={classes.dialogContent} ref={dialogContentRef} onScroll={handleScroll}>
            <div style={{margin:'auto',width:'150px'}}><LocalizationOptions /></div>
            <div dangerouslySetInnerHTML={{ __html: t("dialog.content", { trans: 'html' }) }}></div>
        </DialogContent>
        <DialogActions sx={{ textAlign: 'center' }}>
          <LoadingButton onClick={handleAgree} loading={btnLoading} color="primary" variant="contained" size="small" endIcon={<Handshake />} >
            I Agree
          </LoadingButton>
        </DialogActions>

        </Dialog>



        {rankMessageopen && <div className={classes.backdropBlur}></div>}
        <Dialog open={rankMessageopen}  onClose={handleRankClose}>
          <DialogTitle>{userData?.rankMessageTitle}</DialogTitle>
          <DialogContent className={classes.dialogContent} ref={dialogContentRef} onScroll={handleScroll}>
            <div dangerouslySetInnerHTML={{ __html: t(userData?.rankMessage, { trans: 'html' }) }}></div>
        </DialogContent>
        <DialogActions sx={{ textAlign: 'center' }}>
          <LoadingButton onClick={handleRankAgree} loading={btnLoading} color="primary" variant="contained" size="small" endIcon={<Handshake />} >
            Confirm
          </LoadingButton>
        </DialogActions>

        </Dialog>


        <Grid item xs={12}>
          <CoinMarketCapWidget />          
        </Grid>

        <Grid item xs={12} xl={3} lg={4}>
        <UpgradePlan />
      </Grid>


      <Grid item xs={12} xl={2.2} lg={4}>
          <UserWalletBalance />
      </Grid>


      <Grid item xs={12} xl={2.2} lg={4}>
        <AccumulativeBonus />
      </Grid>


          <Grid item xs={12} xl={4.6} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ObjectCountRevenue
                  value={`${(userData?.current_balance || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
                  title={t("pages.title.EWalletBalance")}
                  color="primary.main"
                  icon={<img src={`${ASSET_IMAGES}/web3/wallet.png`} style={{height:'70px'}}  className="animate-scale" />}
                  vertical={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ObjectCountRevenue
                  value={`${(userData?.totalPairingBonus || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
                  title={t("sidebar.menuItem.WeakLegBonus")}
                  color="info.main"
                  icon={<img src={`${ASSET_IMAGES}/web3/binary.png`} style={{height:'70px'}}  className="animate-scale" />}
                  vertical={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ObjectCountRevenue
                  value={`${(userData?.totalLevelBonus || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
                  title={t("pages.title.MatcingBonus")}
                  color="success.main"
                  icon={<img src={`${ASSET_IMAGES}/web3/levelincome.png`} style={{width:'80px', marginLeft:'10px'}}  className="animate-scale" />}
                  vertical={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ObjectCountRevenue
                  value={`${(userData?.totalRefBonus || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
                  title={t("pages.title.ReferralBonus")}
                  color="info.main"
                  icon={<img src={`${ASSET_IMAGES}/web3/referral.png`} style={{height:'70px'}}  className="animate-pulse" />}
                  vertical={true}
                />
              </Grid>
            </Grid>
          </Grid>


      <Grid item xs={12} lg={4}> 
        <EarnRewardCard />
      </Grid>

      
      <Grid item xs={12} lg={8}>


        <Grid container spacing={2}>
          
        <Grid item xs={4}>
            <UserRank />
        </Grid>
          <Grid item sm={8}>
            <PortfolioBalance totalroi={userData?.totalEarningdbData?.total_earning} weeklyroi={userData?.totalEarningdbData?.weekly_earning} monthlyroi={userData?.totalEarningdbData?.monthly_earning} />
          </Grid>

          
          <Grid item xs={12}>
            <CounrDownTimer2 />
          </Grid>

        </Grid>
      </Grid>
      {/* <Grid item xs={12} lg={6}>
        <EarningExpenses earning={userData?.totalEarning} spending={userData?.totalSpending} />
      </Grid> */}
      <Grid item xs={12}>
        <Referralusers
          picturelink={picturelink}
          referralusersdata={referralusersdata}
          
        />
      </Grid>

    </Grid>
  );
};

export default Crypto;
