import React, { useEffect, useState } from "react";
import PackageBox from "./components/PackageBox";
import { Alert, CircularProgress, Grid } from "@mui/material";
import { postRequest } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ASSET_IMAGES } from "app/utils/constants/paths";


const BuyPackage = () => {
	const {t} = useTranslation();
  const [packages, setpackages] = useState([]);
  const [loading, setloading] = useState(true);

  const getPackagesList = () => {
    postRequest(
      "/getpackageslist",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setpackages(response?.data?.data);
          setloading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getPackagesList();
  }, []);

  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (
    <Grid>
      <Grid>
      {/* <Alert variant="filled" severity="success"  >
            You already a member of {'OMEGA JR'} with the package amount of {'$250 US'}
        </Alert> */}
      </Grid>
      <Grid>
      <Grid container spacing={2} mt={2}>
      
      {packages.map((pdata, index) => (
        <Grid sx={{ marginBottom: 3 }} item sm={4} key={index}>
          <PackageBox pdata={pdata} />
        </Grid>
      ))}
    </Grid>
      </Grid>
    </Grid>
    
  );
};

export default BuyPackage;
