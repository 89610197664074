import React, { useContext, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
    Alert,
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { postRequest } from "backendServices/ApiCalls";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Spinner from "app/shared/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object().shape({
    identityType: yup.string().required("Identity Type is required"),
    idcardFront: yup.mixed().required("Front picture is required"),

    idcardBack: yup.mixed().when('identityType', {
      is: "Driver's  License",
      then: yup.mixed().required("Back picture is required"),
      otherwise: yup.mixed(), // No requirement when identityType is not 'Driving License'
    }),
    residentialAddress: yup.string().required("Residential Address is required"),
    name: yup.string().required("Name is required"),
    email: yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: yup.string().required("Phone # is required"),
  });


  
const KycPopup = ({ setOpen, setLoader }) => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [imageFront, setImageFront] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const [selectedValue, setSelectedValue] = useState("Select Type");
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setImageBack(null);
    setImageFront(null);

  };

  const handleUpload = (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          if (base64Data) {
            if (field === "idcardFront") {
              setImageFront(base64Data);
            } else if (field === "idcardBack") {
              setImageBack(base64Data);
            }
            console.log("Image loaded successfully!");
          } else {
            console.log("Error loading image.");
          }
        };
        reader.readAsDataURL(selectedFile); // Read the selected file as data URL
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    const formData = new FormData();
    formData.append("residentialAddress", data.residentialAddress);
    formData.append("identityType", data.identityType);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    if (selectedValue === "Passport") {
      if (!imageFront) {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
        return; // Don't proceed with form submission
      } else {
        formData.append("idcardFront", imageFront);
      }
    } else if (selectedValue === "Driving License") {
      if (!imageFront || !imageBack) {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
        return; // Don't proceed with form submission
      } else {
        formData.append("idcardFront", imageFront);
        formData.append("idcardBack", imageBack);
      }
    }
    console.log('imageFront', imageFront)
    postRequest(
      "/uploadkycdata",
      formData,
      async (response) => {
        if (response?.data?.status === "error") {
          await setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
            await setalertData({
                show: true,
                message: response?.data?.message,
                variant: "success",
              });
          await setloginUserData((prevState) => ({
            ...prevState,
            kyc_status: "Uploaded",
          }));
          await setSubmitting(false);
          await resetForm();
          await setImageBack(null);
          await setImageFront(null);

          await setOpen(false);
          await setLoader(false);
        } else {
            await setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        setSubmitting(false);
        setalertData({
          show: true,
          message: error,
          variant: "error",
        });
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

        <Formik
          initialValues={{
            name: `${loginUserData?.firstname} ${loginUserData?.lastname}`,
            email: loginUserData?.email,
            phone: loginUserData?.mobile,
            residentialAddress: loginUserData?.address,

          }}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleSubmit(data, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, setFieldValue,touched, errors }) => (
            <Form>
            <Grid container spacing={2}>
                <Grid item xs={12}>{t("pages.title.uploadDoc")}</Grid>
                  <Grid item xs={12} style={{ marginBottom: "10px" }}>
                    <Alert severity="warning" sx={{fontSize:'11px'}}>
                    {t("pages.title.extallowed")}
                    </Alert>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 1 }}>
            
                  <Field
                    as={TextField}
                    select
                    id="identityType"
                    name="identityType"
                    label={t("pages.title.doctype")}
                    fullWidth
                    variant="outlined"
                    value={selectedValue}
                    onChange={(e) => {
                      handleSelectChange(e);
                      setFieldValue("identityType", e.target.value);
                    }}
                  >
                    <MenuItem value="Select Type">
                    {t("pages.title.doctype")}
                    </MenuItem>
                    <MenuItem value="Passport">{t("pages.title.passport")}</MenuItem>
                    <MenuItem value="Driving License">{t("pages.title.lisenceId")}</MenuItem>
                  </Field>
                  </Grid>
                  {selectedValue === "Passport" && (
                    <>
                      <Grid item xs={12}>
                        <ListItem sx={{ px: 0, pb: 0 }}>
                          <ListItemText
                            primary={
                              <>
                                <label>{t("pages.title.fside")} *</label>
                                <TextField
                                  onChange={(event) => {
                                    handleUpload(event, "idcardFront");
                                    setFieldValue(
                                      "idcardFront",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                  required
                                  name="idcardFront"
                                  type="file"
                                  margin="normal"
                                  sx={{ marginBottom: "0px", width: "100%" }}
                                  InputProps={{
                                    endAdornment: (
                                      <CameraAltIcon
                                        fontSize={"small"}
                                        color={"success"}
                                      />
                                    ),
                                  }}
                                  error={errors.idcardFront}
                                  helperText={errors.idcardFront}
                                />  

                                <br />
                                {imageFront && (
                                  <img
                                    src={imageFront}
                                    alt="Passport Front Preview"
                                    style={{
                                      maxWidth: "100px",
                                      marginTop: "10px",
                                    }}
                                  />
                                )}
                              </>
                            }
                          />
                        </ListItem>
                      </Grid>

                    </>
                  )}
                  {selectedValue === "Driving License" && (
                    <>
                      <Grid item xs={6} sx={{ mt: 1 }}>
                        <ListItem sx={{ px: 0, pb: 0 }}>
                          <ListItemText
                            primary={
                              <>
                                <label>{t("pages.title.fside")} *</label>
                                <TextField
                                  onChange={(event) => {
                                    handleUpload(event, "idcardFront");
                                    setFieldValue(
                                      "idcardFront",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                  required
                                  name="idcardFront"
                                  type="file"
                                  margin="normal"
                                  sx={{ marginBottom: "0px", width: "100%" }}
                                  InputProps={{
                                    endAdornment: (
                                      <CameraAltIcon
                                        fontSize={"small"}
                                        color={"success"}
                                      />
                                    ),
                                  }}
                                  error={errors.idcardFront}
                                  helperText={errors.idcardFront}
                                />
                                <br />
                                {imageFront && (
                                  <img
                                    src={imageFront}
                                    alt="ID Front Preview"
                                    style={{
                                      maxWidth: "100px",
                                      marginTop: "10px",
                                    }}
                                  />
                                )}
                              </>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={6} sx={{ mt: 1 }}>
                        <ListItem sx={{ px: 0, pb: 0 }}>
                          <ListItemText
                            primary={
                              <>
                                <label>{t("pages.title.bside")} *</label>
                                <TextField
                                  onChange={(event) => {
                                    handleUpload(event, "idcardBack");
                                    setFieldValue(
                                      "idcardBack",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                  required
                                  name="idcardBack"
                                  type="file"
                                  margin="normal"
                                  sx={{ marginBottom: "0px", width: "100%" }}
                                  InputProps={{
                                    endAdornment: (
                                      <CameraAltIcon
                                        fontSize={"small"}
                                        color={"success"}
                                      />
                                    ),
                                  }}
                                  error={errors.idcardBack}
                                  helperText={errors.idcardBack}
                                />
                                <br />
                                {imageBack && (
                                  <img
                                    src={imageBack}
                                    alt="ID Front Preview"
                                    style={{
                                      maxWidth: "100px",
                                      marginTop: "10px",
                                    }}
                                  />
                                )}
                              </>
                            }
                          />
                        </ListItem>
                      </Grid>

                    </>
                  )}

                      <Grid item xs={6} sx={{ mt: 1 }}>
                        <JumboTextField label={t("pages.title.fullname")} fullWidth name="name" type="text" />
                      </Grid>
                      <Grid item xs={6} sx={{ mt: 1 }}>
                        <JumboTextField label={t("pages.title.Email")}  fullWidth name="email" type="text" />
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <JumboTextField label={t("pages.title.Mobile")} fullWidth name="phone" type="text" />
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <JumboTextField
                          fullWidth
                          label={t("pages.title.resaddress")}
                          name="residentialAddress"
                          type="text"
                          multiline
                          rows={2}
                        />
                      </Grid>
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    {selectedValue !== "Select Type" && (
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.Submit")}
                      </LoadingButton>
                    )}
                  </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
    </Grid>
  );
};

export default KycPopup;
