import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ViewIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useTranslation } from "react-i18next";

const Sent = () => {
  const {t} = useTranslation();
  const [messages, setMessagesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});

  const messagesData = () => {
    let params = {
      type: "sender",
      userid: "admin",
    };
    postRequest(
      "/getmessages",
      params,
      (response) => {
        setMessagesData(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    messagesData();
  }, []);

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "receiverusername",
      headerName: `${t("pages.title.Receiver")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      
      groupable: false,
      aggregable: false,
    },
    {
      field: "title",
      headerName: `${t("pages.title.Title")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      
      groupable: false,
      aggregable: false,
    },
    {
      field: "message",
      headerName: `${t("pages.title.Message")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 400,
      
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <div dangerouslySetInnerHTML={{ __html: params.value }} />
      ),
    },
    {
      field: "status",
      headerName: `${t("pages.title.Status")}`,
      width: 200,
    },
    {
      field: "createdat",
      headerName: `${t("pages.title.Date")}`,
      width: 200,
      renderCell: (params) => {
        return format(new Date(params.row.createdat), "MM/dd/yyyy");
      },
    },
    {
      field: "actions",
      headerName: `${t("pages.title.Actions")}`,
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleViewClick(params.row)} color="primary">
          <ViewIcon />
        </IconButton>
      ),
    },
  ];

  const handleViewClick = (row) => {
    setSelectedMessage(row);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const rows = messages;

  return (
    <JumboDemoCard
      title={t("pages.title.SentMessage")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <h2>{selectedMessage.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: selectedMessage.message }} />

          <br />
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <ScheduleIcon fontSize="small" color="action" />
            <span style={{ marginLeft: "4px" }}>
              {format(
                new Date(
                  selectedMessage.createdat || "2024-01-24T09:07:27.000Z"
                ),
                "yyyy-MM-dd hh:mm:ss a"
              )}
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </JumboDemoCard>
  );
};

export default Sent;
