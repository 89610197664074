import React, { useState, useEffect } from 'react';
import {Grid} from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails, Typography  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { postRequest } from 'backendServices/ApiCalls';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import PopularArticles from 'app/shared/widgets/PopularArticles';


const useStyles = makeStyles((theme) => ({
  newsContainer: {
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const News = () => {
  const classes = useStyles();


    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
      <Grid item sm={12} >
        <PopularArticles />
      </Grid>
      </Grid>
    );
};

export default News;








