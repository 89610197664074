import {
    Alert,
    Container,
    Grid,
    Tab,
    Tabs,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { useState } from "react";
  import {
    getproduct,
    postMethod,
    postRequest
  } from "backendServices/ApiCalls";
  import { useEffect } from "react";
  import { useContext } from "react";
  import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SingleProduct from "./SingleProduct";
  
  const Products = () => {
    
    const { loginUserData } =
      useContext(CustomProvider);
    const [manageProducts, setManageProducts] = useState([]);
  
    const [imageurl, setImageUrl] = useState(null);
  
  
    const GetAllProducts = () => {
      postMethod('/user/healiving/api/getproduct','',
        (response) => {
          setManageProducts(response?.data?.data);
          setImageUrl(response?.data?.imageURL);
          if (response?.data === "success") {
            // change in everyone
            console.log("response get Successfully");
          }
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
    };
  
    useEffect(() => {
      GetAllProducts();
    }, []);
  

  
    return (
      <>
        <Container>
  
  
          <Typography
            variant="h3"
            color="#F39711"
            p={2}
            textAlign="center"
            sx={{ backgroundColor: "#a785ed", color:'#000' }}
          >
            Product Items
          </Typography>
  
          {
            loginUserData?.status === "pending" ? (
              <Alert severity="warning" sx={{marginTop:2, marginBottom:2}}>Your are not an active member of the platform. Please buy a package first to continue shopping.</Alert>
            )
            :
            null
          }

  
          <Grid container spacing={2} mt={3}>
  
            {manageProducts.map(
              (item) => (
                <SingleProduct item={item} imageurl={imageurl}  />
              )
            )}
          </Grid>
        </Container>
      </>
    );
  };
  
  export default Products;