import React from 'react';
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { alpha } from "@mui/material/styles";

const JumboIconButton = ({ children, elevation, badge, newnotification, ...restProps }) => {
  return (
    <IconButton
      sx={{
        width: 40,
        height: 40,
        boxShadow: elevation,
        padding: 1.25,
      }}
      {...restProps}
    >

            <Badge
            badgeContent={newnotification !== '0' ? newnotification : null}
            color='secondary'
              sx={{
                '& .MuiBadge-badge': {
                  top: -10,
                  right: -10,
                },

              }}
            >
              {children}
            </Badge>
       
    </IconButton>
  );
};

export default JumboIconButton;
