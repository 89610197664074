import React, { useState } from 'react';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {ListItemButton, Typography} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ViewIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import MessageDetails from 'app/pages/messages/MessageDetails';
import { Send } from '@mui/icons-material';
import { postRequest } from 'backendServices/ApiCalls';
import ChatBox from 'app/pages/messages/ChatBox';
const MessageItem = ({item}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({});
    const [loadingRows, setLoadingRows] = useState({});
    const [messages, setMessagesData] = useState([]);

    const handleViewClick = async (row) => {
        await setLoadingRows(prevLoadingRows => ({
            ...prevLoadingRows,
            [row.id]: true, // Assuming you have a unique identifier like 'id' for each row
          }));
        await  postRequest(
            "/updatemessage",
            {msgId:row?.id},
            (response) => {},(error) => {
              console.log(error?.response?.data);
            }
          );
        await setSelectedMessage(row);
        await setDialogOpen(true);
      };
    
      const handleCloseDialog = () => {
        setDialogOpen(false);
      };
      const truncatedMessage =
      item.message.length > 40
        ? item.message.substring(0, 40) + '...' // Truncate the text and add "..."
        : item.message

    return (
        <>

        <ListItemButton component={"li"} onClick={()=>handleViewClick(item)} disableRipple>
            <ListItemAvatar>
                <Avatar sx={{bgcolor:'#fff'}}>
                    <Send color='primary' />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Typography variant={"h6"} mb={.25}>{item.title}</Typography>}
                          secondary={<Typography noWrap color={"text.secondary"}>
                                <div dangerouslySetInnerHTML={{ __html: truncatedMessage }} />

                          </Typography>}
            />
            {/* <IconButton elevation={1} edge={"end"} size={"small"}><MoreHorizIcon/></IconButton> */}
        </ListItemButton>

        <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogContent>
        <ChatBox selectedMessage={selectedMessage} setLoadingRows={setLoadingRows} setMessagesData={setMessagesData} messages={messages} />            

        </DialogContent>
        <DialogActions>
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
        >
            <CloseIcon />
        </IconButton>
        </DialogActions>
        </Dialog>

</>
    );
};

export default MessageItem;