import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Chip, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
const UserRank = () => {
  const { t } = useTranslation();
  const { loginUserData, loading } = useContext(CustomProvider);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const nav = useNavigate()

  if (loading) {
    return "";
  }

  const handleClick = async (url) => {
    if (!loginUserData || !loginUserData.walletaddress) {
      await nav('/market-place')
    } else {
      // Open the link if the wallet address is not empty
      await nav(url)
    }
  };
  let imgpath = '' 
  if(loginUserData?.pkgid===21 || loginUserData?.pkgid===22){
     imgpath=getAssetPath(`${ASSET_IMAGES}/packages/ALPHA.png`, "102x102")

  }else{
     imgpath=getAssetPath(`${ASSET_IMAGES}/packages/OMEGA.png`, "102x102")

  }

  return (
    <Div style={{height:'100%', width:'100%', background:`url(${ASSET_IMAGES}/ranks/${loginUserData?.rankimage})`,backgroundSize:'contain', backgroundPosition:'center center', borderRadius:'10px'}}>

    </Div>
  );
};

export default UserRank;
