import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UpgradeModal from "./UpgradeModal";

const UpgradePackageBox = ({ pdata }) => {
  const nav = useNavigate()
	const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [nextPackageAmount, setNextPackageAmount] = React.useState(null);

  const { loginUserData } = useContext(CustomProvider);

  useEffect(() => {
    if (loginUserData && pdata) {
      const investment = loginUserData.investment;
      if (investment < pdata.amount1) {
        setNextPackageAmount(pdata.amount1 - investment);
      } else if (investment < pdata.amount2) {
        setNextPackageAmount(pdata.amount2 - investment);
      } else if (investment < pdata.amount3) {
        setNextPackageAmount(pdata.amount3 - investment);
      } else {
        // Handle the case where investment is greater than 500
        // You might want to set a default value or handle it differently based on your requirements
      }
    }
  }, [loginUserData, pdata]);

  return (
    <Card sx={{ textAlign: "center" }}>
        <UpgradeModal open={open} setOpen={setOpen} nextPackageAmount={nextPackageAmount}/>
      <CardContent
        sx={{
          backgroundImage: "linear-gradient(#0fc9a1, #027b74)",
          backgroundRepeat: "repeat-x",
          py: 6,
        }}
      >
        <Typography
          variant={"h1"}
          fontWeight={"300"}
          sx={{ fontSize: "3rem", color: "common.white", mb: 0 }}
        >
          {pdata?.title}
        </Typography>

     
       
      </CardContent>
      <CardContent sx={{ position: "relative" }}>
        <Fab
          aria-label="volume"
          color="secondary"
          sx={{
            position: "absolute",
            left: "50%",
            top: "0",
            height: 80,
            width: 80,
            transform: "translate(-50%, -50%)",
            color: "#000",
          }} 
        >
            <img src={`${ASSET_IMAGES}/packages/omega.png`} style={{width:'45px'}} alt={''} />
          
        </Fab>
        
        <TableContainer sx={{ mt: 2, mb: 2 }}>
       
            <Typography variant={"h2"} fontWeight={"500"} mb={.5} sx={{mt: 2, color: 'white'}}>{t("pages.title.NFTsPackages")}</Typography>
        </TableContainer>

<Grid container spacing={2}>
          <Grid item xs={12}>
             <Button variant={"contained"} sx={{ fontSize: '30px', fontWeight: '600' }} color={"primary"} disableElevation>{pdata?.amount_details}</Button>
          </Grid>
          <Grid item xs={12}>
                {
                loginUserData?.pkgid >= pdata?.id && loginUserData?.investment >= pdata?.amount3 ? (
                    <Button   variant={"contained"} color={"warning"} disabled  sx={{ fontWeight: '600'}} >
                      {t("pages.title.Alreadyamember")}
                </Button>
                )
                :
                (
                    <Button onClick={()=>setOpen(true)}  variant={"contained"} color={"warning"}  sx={{ fontWeight: '600'}} >
                         {t("sidebar.menuItem.UpgradePackage")}
                    </Button>
                )
                }
          </Grid>
</Grid>




            </CardContent>
        </Card>
    )
}

export default UpgradePackageBox
