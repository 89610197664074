import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { postRequest } from "backendServices/ApiCalls";
import { useEffect } from "react";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div";
import Image from "material-ui-image";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { next } from "stylis";
import { useTranslation } from "react-i18next";

const Ecommerce = () => {
  const {t} = useTranslation();
  const [nftCollection, setNftCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const lastRowRef = useRef(null);

  const GetCollection = (nextId = "") => {
    // if (isLoading && nextPage !== "")
    // {
    //   return; // Prevent multiple simultaneous requests
    // }

    postRequest(
      "/getusercollection",
      '',
      async (response) => {
        if (response?.data?.status === "success") {
          const newNftCollection = response?.data?.data;
          await setNftCollection(newNftCollection);
          await setIsLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    GetCollection();
  }, []);

  return (
    <>
      <Container>
        <Typography
          variant="h3"
          color="#F39711"
          p={2}
          textAlign="center"
          sx={{ backgroundColor: "#02555b", color: "#fff" }}
        >
          {t("pages.title.MyCollection")}
        </Typography>

        <Grid container spacing={2} mt={3}>
          
          {nftCollection.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.identifier}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Image
                      src={item.imageurl}
                      alt={item.title}
                      style={{
                        borderRadius: "5px",
                        border: "none",
                        boxShadow: "0px 0px 4px 0px ",
                      }}
                      loading={
                        <Skeleton
                          sx={{ bgcolor: "grey.900" }}
                          variant="rectangular"
                          width={213}
                          height={213}
                        />
                      }
                    />
                    <Typography
                      pt={2}
                      variant="h5"
                      fontWeight="bold"
                      color="darkgray"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      pt={2}
                      variant="h5"
                      fontWeight="bold"
                      color="darkgray"
                    >
                      Price ($): {(item?.usdprice || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}
                    </Typography>

                    <Typography
                      pt={2}
                      variant="h5"
                      fontWeight="bold"
                      color="darkgray"
                    >
                      Price ({item.token}): {item.tokenprice}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              {index === nftCollection.length - 1 && (
                <div ref={lastRowRef}></div>
              )}
            </Grid>
          ))}
        </Grid>

        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  );
};

export default Ecommerce;
