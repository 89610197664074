import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { Alert, Button, CircularProgress, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { postRequest } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "app/utils/constants/paths";

const WebsiteGuide = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [loading, setLading] = useState(true);


  const GetallVideos = () => {
      let params= {"category":"Website Navigation"}
      postRequest('/getvideos',params,(response) => {
          setVideos(response?.data?.data);
          setLading(false)
      }, (error) => {
          console.log(error?.response?.data);
      })
  }

  useEffect(() => {
    GetallVideos();
  }, [])

  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
     
      <Grid item sm={8}>

        {
videos.length > 0 ? (
  videos.map((vdata, index) => ( // added index as a second parameter
    <JumboDemoCard
      key={index} // added key prop for each iteration
      title={vdata?.name}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Stack direction="column" spacing={2} alignItems="center" sx={{ width: '100%' }}>
        {/* <Stack direction="row" spacing={2}>
          <Button variant="contained"> <SimCardDownloadIcon/>{t("sidebar.menuItem.englishpdf")}</Button>
          <Button variant="contained"> <SimCardDownloadIcon/>{t("sidebar.menuItem.spanishpdf")}</Button>
        </Stack> */}
        {/* Embed YouTube video in a container for better control */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <iframe
            width="560"
            height="315"
            src={`${vdata?.url}?autoplay=1&controls=0&rel=0&modestbranding=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Stack>
    </JumboDemoCard>
  ))
) : (
  <Alert severity="info">
    {t("pages.title.nocontent")}
  </Alert>
)


        }

      </Grid>
    </Grid>
  );
};

export default WebsiteGuide;
