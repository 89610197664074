import React, { useContext } from 'react';
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import {Badge, ThemeProvider} from "@mui/material";
import { useEffect } from 'react';
import { postRequest } from 'backendServices/ApiCalls';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { ShoppingCart } from '@mui/icons-material';


const CartTriggerButton = () => {
    const {headerTheme} = useJumboHeaderTheme();
    const {totalCartQuantity } = useContext(CustomProvider);

    return (
        <ThemeProvider theme={headerTheme}>
            <JumboIconButton elevation={25}>
                <Badge badgeContent={totalCartQuantity} color="warning">
                    <ShoppingCart color='warning'/>
                </Badge>
            </JumboIconButton>
        </ThemeProvider>
    );
};

export default CartTriggerButton;
