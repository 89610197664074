import React from 'react';
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import {ThemeProvider} from "@mui/material";
import { useEffect } from 'react';
import { postRequest } from 'backendServices/ApiCalls';


const MessagesTriggerButton = ({newmessages,setNewMessages}) => {
    const {headerTheme} = useJumboHeaderTheme();

    function updateNotification() {
        postRequest(
          "/updateprofiledata",
          {
            newMessage:0
          },
          (response) => {
            setNewMessages('0')
            console.log(response?.data);

          },
          (error) => {
            console.log(error?.response?.data);
          }
        );
      }



    return (
        <ThemeProvider theme={headerTheme}>
            <JumboIconButton onClick={()=>updateNotification()} newnotification={newmessages}  elevation={25}>
                <ChatBubbleOutlineOutlinedIcon sx={{fontSize: '1rem'}}/>
            </JumboIconButton>
        </ThemeProvider>
    );
};

export default MessagesTriggerButton;
