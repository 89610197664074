import React, { useContext, useEffect, useState } from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { AppContext } from 'app/AppContext';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';


const Footer = () => {
    const {companyName} = useContext(AppContext)
    const {loading} = useContext(CustomProvider)
    const { t } = useTranslation();



    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"body1"} color={"text.primary"}>{companyName} ©  2024 {companyName} {t("pages.title.allrights")} </Typography>

                <Typography variant={"body1"} sx={{}} color={"text.primary"}>{t("pages.title.developedby")} <a href="https://threearrowstech.com" target='_blank' style={{color:'#3BD2A2', fontSize:'14px'}}><b>Three Arrows Tech</b></a></Typography>
                

            </Div>
        </Div>
    );
};

export default Footer;
