import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Alert, Autocomplete, Box, Grid, TextField } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { countries } from "app/pages/components/mui/AutoCompletes/data";
import { useContext } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { postRequest, updateProfileData } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  walletaddress: yup
    .string("Enter Wallet Address")
    .required("Wallet address is required"),
});

const WalletForm1 = ({handleClose}) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState("");
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [loading, setloading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      walletaddress: data.walletaddress,
      updateType:"walletaddress"
    };
    updateProfileData(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: `${t("pages.title.wallet_already_existed")}`,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: `${t("pages.title.Walletaddressupdated")}`,
            variant: "success",
          });
          setSubmitting(false);
          handleClose();
          setloginUserData((prevState) => ({
            ...prevState,
            walletaddress: data.walletaddress,
          }));
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(async () => {
    await setloading(false);
  }, [loginUserData]);

  return (
    <JumboCardQuick title={t("pages.title.UpdateWalletAddress")} sx={{ mt: 2 }}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={{
            walletaddress: userData.walletaddress || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue, touched, errors }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {t("pages.title.samewalletaddress")}
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    name="walletaddress"
                    label={t("pages.title.WalletAddress")}
                    type="text"
                    disabled={userData.walletaddress !== null ? true : false}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                    disabled={userData.walletaddress !== null ? true : false}
                  >
                    {t("pages.title.UpdateWalletAddress")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default WalletForm1;
