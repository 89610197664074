import React from 'react';
import ReferralList from "./ReferralList";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import ViewReferrals from 'app/pages/referrals/viewreferrals';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { People } from '@mui/icons-material';

const ReferralUsers = ({scrollHeight,referralusersdata,picturelink}) => {

    const {t} = useTranslation();
    const nav = useNavigate()
    return (
        <JumboCardQuick
            title={t("pages.title.referralUsers")}
            subheader={t("pages.title.referralUsers")}
            wrapperSx={{p: 2,}}
            headerSx={{borderBottom: 1, borderBottomColor: 'divider'}}
        >
            <ViewReferrals limit={4} />

            <div style={{width:'200px', textAlign:'center', margin:'auto', marginTop:'20px'}}>
            <Button variant='contained' onClick={()=>nav('/referrals')} size='small' endIcon={<People />} sx={{borderRadius:'50px', color:'#fff'}} color='info'>
                {t("pages.title.ViewAll")}
            </Button>
            </div>

            {/* <JumboScrollbar
                autoHeight
                autoHeightMin={scrollHeight ? scrollHeight : 366}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <ReferralList picturelink={picturelink} referralusersdata={referralusersdata}/>
            </JumboScrollbar> */}
        </JumboCardQuick>
    );
};

export default ReferralUsers
;
