import React, { useContext, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import Payout from "app/pages/payout/payout";
import { Close } from "@mui/icons-material";
import ResidualPayout from "app/pages/payout/ResidualPayout";
import SocialTasks from "app/pages/payout/SocialTasks";

const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backdropFilter: 'blur(18px)',
    '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal - 1,
  },
}));


const UserWalletBalance = () => {
  const { t } = useTranslation();
  const { loginUserData, loading } = useContext(CustomProvider);
  const [btnLoading, setbtnLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSocial, setSocialOpen] = useState(false)
  const [instagram, setInstagram] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [twitterUsername, setTwitterUsername] = useState('');
  const [post, setPost] = useState(null);

  const classes = useStyles();

  const handlePayout = () =>{
    setbtnLoading(true)
    setTimeout(() => {
    setSocialOpen(true)
    }, 2000)
  }

  const handleClose = () => {
    setbtnLoading(false)
    setOpen(false);
};



const handleCloseSocial = () => {
  setbtnLoading(false)
  setSocialOpen(false);
};

  return (
    <JumboCardQuick
      sx={{ textAlign: "center", color: "common.white" }}
    >

{open && <div className={classes.backdropBlur}></div>}
        <Dialog open={open}  onClose={handleClose}>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
          <DialogContent>
            <ResidualPayout setOpen={setOpen} instagram={instagram} setInstagram={setInstagram} twitter={twitter} setTwitter={setTwitter} twitterUsername={twitterUsername} setTwitterUsername={setTwitterUsername} post={post} setPost={setPost} />
          </DialogContent>


        </Dialog>



        {openSocial && <div className={classes.backdropBlur}></div>}
        <Dialog open={openSocial}  onClose={handleCloseSocial}>
          <IconButton
          aria-label="close"
          onClick={handleCloseSocial}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
          <DialogContent>
            <SocialTasks instagram={instagram} setInstagram={setInstagram} twitter={twitter} setTwitter={setTwitter} twitterUsername={twitterUsername} setTwitterUsername={setTwitterUsername} post={post} setPost={setPost} setOpen={setOpen} setSocialOpen={setSocialOpen} />
          </DialogContent>


        </Dialog>




      <Typography variant={"h4"} color={'white'}>{t("widgets.title.residualBonus")}</Typography>
      <Div
        sx={{ margin: "auto", minWidth: 0, justifyContent: "center", mb: 0,height:'102px', width:'102px', borderRadius:'50%', background:'#3bd2a2', pt:1, boxShadow:'1px 0px 15px #3bd2a2', overflow:'hidden' }}
      >

        <img
        className="animate-scale"
          src={`${ASSET_IMAGES}/web3/mining.png`}
          alt={``}
          width={'80%'}
        />
      

      </Div>

      <Typography variant={"body1"} color={"common.white"} sx={{ mt: 1,mb: 1, fontSize:'20px' }}>
        {`${(loginUserData?.residualBonus || 0)} Matic`}
      </Typography>



    <LoadingButton 
      loading={btnLoading} onClick={handlePayout}  variant="contained" size="small" sx={{borderRadius:'50px'}} color="success" >{t("sidebar.menuItem.rpayout")}</LoadingButton>

{/* 
    <Chip
      sx={{fontSize:25}}
      label={`${(loginUserData?.current_balance || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
      color={'success'}
    /> */}


    </JumboCardQuick>
  );
};

export default UserWalletBalance;
