import React, { useEffect, useState } from "react";
import PopularAgents from "../../shared/widgets/PopularAgents";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { roidata, referralusers, postRequest } from "backendServices/ApiCalls";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div/Div";
import { useTranslation } from "react-i18next";
import { ASSET_IMAGES } from "app/utils/constants/paths";

export default function ViewReferrals({limit}) {
  const {t} = useTranslation();
  const { ...authOptions } = useJumboAuth();
  const [picturelink, setPictureLink] = useState([]);
  const [referralusersdata, setReferralUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  let dlimit
  limit ? dlimit = limit : dlimit = 0
  
  const ReferralUsers = () => {
    postRequest('/referralusers',{limit:dlimit},
      (response) => {
        setReferralUsersData(response?.data?.data?.entries);
        setPictureLink(response?.data?.data?.picturelink);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(true);
      }
    );
  };

  useEffect(() => {
    ReferralUsers();
  }, []);

  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (
    <Grid item xs={12}>
      <PopularAgents picturelink={picturelink} data={referralusersdata} />
    </Grid>
  );
}
