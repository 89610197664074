import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { postRequest } from "backendServices/ApiCalls";
import { useEffect } from "react";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Image from "material-ui-image";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import TransitionsModal from "./Modal";
import { makeStyles } from '@mui/styles';
import KycPopup from "../kyc/KycPopup";
import { useJumboTheme } from "@jumbo/hooks";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import EcommerceHeader from "./EcommerceHeader";
import {alpha} from "@mui/material/styles";
import { getAssetPath } from "app/utils/appHelpers";
import {ASSET_IMAGES} from "app/utils/constants/paths";


const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backdropFilter: 'blur(8px)',
    '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal - 1,
  },
}))

const Ecommerce = () => {
  const {theme} = useJumboTheme();
  const { t } = useTranslation();
  const { loginUserData } = useContext(CustomProvider);
  const [nftCollection, setNftCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPage, setNextPage] = useState("");
  const nav = useNavigate()
  const lastRowRef = useRef(null);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [kycOpen, setKycOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const { collection } = useParams();
  const collectionName = collection.replace(/-/g, ' ');

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });


  const GetCollection = (nextId = "") => {
    setIsLoading(true);
    let params = {
      nextId,
      limit: 6,
      collectionSlug:collection
    };

    postRequest(
      "/getcollection",
      params,
      async (response) => {
        if (response?.data?.status === "success") {
          const newNftCollection = response?.data?.data?.nfts;
          // await setNftCollection((prevCollection) => [
          //   ...prevCollection,
          //   ...newNftCollection,
          // ]);
          await setNftCollection(newNftCollection)
          await setNextPage(response?.data?.data?.next);
          await setIsLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setIsLoading(false);
      }
    );
  };

  
  useEffect(() => {
    GetCollection();
  }, []);



  let abc = 'Verified'
  const handleClick = async (url) => {
    if (!loginUserData || !loginUserData.walletaddress) {

      await setalertData({
        show: true,
        message: "Please update your wallet address first to buy NFT",
        variant: "error",
      })
      setOpen(true)
    } else if (abc !== "Verified") {
      await setalertData({
        show: true,
        message: "Please verify your identity first",
        variant: "error",
      })
      await setKycOpen(true)
    } else {
      // Open the link if the wallet address is not empty
      await window.open(url, '_blank', 'noopener noreferrer');
    }
  };
  const useProfileLayout = () => {
    const {theme} = useJumboTheme();
    return React.useMemo(() => ({
        header: {
            sx: {
                mt: -4,
                    mb: -7.25,
                    mx: {xs: -4, lg: -6},
                p: {xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11)},
                background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/${collection}.png`,"1920x580")}) no-repeat center`,
                    backgroundSize: 'cover',
                    color: 'common.white',
                    position: 'relative',

                    '&::after': {
                    display: 'inline-block',
                        position: 'absolute',
                        content: `''`,
                        inset: 0,
                        backgroundColor: alpha(theme.palette.common.black, .65)
                }
            }
        },
        sidebar: {
            sx: {
                mr: 3.75,
                    width: {xs: '100%', lg: '33%'},
                [theme.breakpoints.down('lg')]: {
                    minHeight: 0,
                        mr: 0,
                        order: 2
                }
            }
        },
        wrapper: {
            sx: {
                [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column'
                }
            }
        },
        main: {
            sx: {
                [theme.breakpoints.down('lg')]: {
                    minHeight: 0
                }
            }
        }
    }), [theme])
}

const profileLayout = useProfileLayout()

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

<JumboContentLayout
                header={<EcommerceHeader collection={collection} collectionName={collectionName} />}
                sidebar={''}
                layoutOptions={profileLayout}
            >

<Container>

{kycOpen && <div className={classes.backdropBlur}></div>}
<Dialog
  open={kycOpen}
  keepMounted
  onClose={() => {
    setKycOpen(false);
  }}
  aria-describedby="alert-dialog-slide-description"
  maxWidth="xs"
>
  <DialogContent sx={{ padding: 2 }}>
    {loginUserData?.kyc_status === "Unverified" ? (
      <KycPopup setKycOpen={setKycOpen} setLoader={setLoader} setalertData={setalertData} />
    ) : loginUserData?.kyc_status === "Uploaded" ? (
      <Alert severity="primary">
        {t("pages.title.alreadysubmitted")}
      </Alert>
    ) : loginUserData?.kyc_status === "Rejected" ? (
      <>
        <Alert severity="error">
        {t("pages.title.prevdocumentrejected")}
          <br />
          <b>{t("pages.title.reason")}: </b>{loginUserData?.kycRejectReason}
        </Alert>
        <KycPopup setOpen={setOpen} setLoader={setLoader} setalertData={setalertData} />
      </>
    ) : loginUserData?.kyc_status === "verified" ? (
      <Alert severity="success">
        {t("pages.title.kycapproved")}
      </Alert>
    ) : null}
  </DialogContent>
  <DialogActions>
    <Button
      color="warning"
      onClick={() => {
        setKycOpen(false);
      }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>

<TransitionsModal open={open} handleClose={handleClose} />
<Paper sx={{backgroundColor:'#111224b5'}}>
<Typography
  variant="h3"
  color="#F39711"
  p={2}
  textAlign="center"
  sx={{color: "#fff", textTransform:'capitalize' }}
>
  {/* {collectionName} */}
  {t("pages.title.ProductItems")}
</Typography>
</Paper>

<Grid container spacing={2} mt={3}>
  {
    loginUserData?.status === 'approved' ? (
      <Grid item xs={12}>
        <Alert severity="info">
          {t("pages.title.NftLimitMessage")}

        </Alert>
      </Grid>
    )
      :
      nftCollection.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={item.identifier}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Image
                  src={item.image_url}
                  alt={item.title}
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0px 0px 4px 0px ",
                  }}
                  loading={
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      width={213}
                      height={213}
                    />
                  }
                />
                <Typography
                  pt={2}
                  variant="h5"
                  fontWeight="bold"
                  color="darkgray"
                >
                  {item.name}
                </Typography>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2 }}
                  onClick={() => handleClick(item.opensea_url)}
                >
                  {t("pages.title.GetNFT")}
                </Button>
              </CardContent>
            </CardActionArea>
          </Card>
          {index === nftCollection.length - 1 && (
            <div ref={lastRowRef}></div>
          )}
        </Grid>
      ))}
  {
    nftCollection.length > 0 && loginUserData?.status === 'pending' ? (
      <Grid item xs={12} mt={3}>
        <Grid container justifyContent="center" alignItems="center">
          <Button className="pulse-button" variant="contained" size="lg" onClick={() => handleClick(`https://opensea.io/collection/${collection}`)} color="warning">{t("pages.title.completecollection")}</Button>
        </Grid>
      </Grid>
    )
      :
      null
  }

</Grid>

{isLoading && (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100px"
  >
    <CircularProgress />
  </Box>
)}

</Container>

</JumboContentLayout>



    </>
  );
};

export default Ecommerce;