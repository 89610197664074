import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { payoutsummary, postRequest } from "backendServices/ApiCalls";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";

const initialState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
      },
    },
  },
};

const PackageHistory = () => {
  const {t} = useTranslation();
  const { loginUserData } = useContext(CustomProvider);
  const [payoutdata, setPayoutData] = useState([]);
  const PayoutData = () => {
    postRequest(
      "/getpackageshistory",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setPayoutData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  console.log("payoutdata", payoutdata);

  useEffect(() => {
    console.log("useeffect");
    PayoutData();
  }, []);

  const CopyToClipboardCell = ({ value }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(value);
      setIsCopied(true);
    };

    return (
      <div>
        <Tooltip title={isCopied ? "Copied" : "Copy to Clipboard"} arrow>
          <IconButton onClick={copyToClipboard} disabled={isCopied}>
            {isCopied ? (
              <CheckCircleIcon color="success" />
            ) : (
              <ContentCopyIcon />
            )}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      field: "packagename",
      headerName: `${t("pages.title.Package")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: `${t("pages.title.Amount")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,
      renderCell: (params) => {
        return `$${params.value}`;
      },
    },
    {
      field: "status",
      headerName: `${t("pages.title.Status")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value === "pending" ? (
          <Chip color="warning" label={t("pages.title.Pending")} />
        ) : params.value === "rejected" ? (
          <Chip color="error" label={t("pages.title.Rejected")} />
        ) : (
          <Chip color="success" label={t("pages.title.Approved")} />
        );
      },
    },
    {
      field: "createdat",
      headerName: `${t("pages.title.RequestedOn")}`,
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
      groupable: false,
      aggregable: false,
    },

  ];

  const rows = payoutdata;
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <JumboDemoCard
      title={t("pages.title.PackageHistory")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default PackageHistory;
