import React, { useContext, useEffect, useState, Suspense } from "react";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";

import { Badge, CircularProgress, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import MessagesDropdown from "app/shared/MessagesDropdown";
import TawkToWidget from "app/shared/widgets/TawkTo/TawkToWidget";
import { Link } from "react-router-dom";
import { ShoppingCart } from "@mui/icons-material";
import CartDropDown from "app/shared/CartDropDown/CartDropDown";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { headerTheme } = useJumboHeaderTheme();
  const [loading, setLoading] = useState(true);

  const { loginUserData } = useContext(CustomProvider);
  let userData = loginUserData;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
        >
          {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />
      )}

      <Stack
        direction="row"
        alignItems="center"
        spacing={1.25}
        sx={{ ml: "auto" }}
      >
        
        {loading === false ? <LocalizationOptions /> : <CircularProgress />}
        {/* <p>
          Your Referrer: <b>{userData.sponsornuserame}</b>
        </p> */}

        {loading === false ? <CartDropDown /> : <CircularProgress />}

        {loading === false ? <MessagesDropdown /> : <CircularProgress />}
        {loading === false ? <NotificationsDropdown /> : <CircularProgress />}
        {loading === false ? <AuthUserDropdown /> : <CircularProgress />}

       
      </Stack>
    </React.Fragment>
  );
};

export default Header;
