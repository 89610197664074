import React, { useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  TableContainer,
  Typography,
} from "@mui/material";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PackageBox = ({ pdata }) => {
  const nav = useNavigate()
	const {t} = useTranslation();
  const { loginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();
  const handleClick = async (url) => {
      await nav(url)
  };
  
    const words = pdata?.title.split(' ');
    const firstWord = words[0]
  return (
    <Card sx={{ textAlign: "center" }}>
      <CardContent
        sx={{
          backgroundImage: "url(images/packagesbg.png)",
          backgroundSize:'cover',
          backgroundRepeat: "repeat-x",
          py: 6,
        }}
      >
        <Typography
          variant={"h1"}
          fontWeight={"900"}
          sx={{ fontSize: "3rem", color: "common.white", mb: 0 }}
        >
          {pdata?.title}
        </Typography>

     
       
      </CardContent>
      <CardContent sx={{ position: "relative" }}>
        <Fab
          aria-label="volume"
          color="secondary"
          sx={{
            position: "absolute",
            left: "50%",
            top: "0",
            height: 80,
            width: 80,
            transform: "translate(-50%, -50%)",
            color: "#000",
            overflow:'hidden'
          }} 
        >
            <img src={pdata?.thumbnail} style={{width:'100%'}} alt={''} />
          
        </Fab>
        <TableContainer sx={{ mt: 2, mb: 2 }}>
        <Typography variant={"h2"} fontWeight={"500"} mb={.5} sx={{mt: 2, color: 'white'}}>{pdata?.amount_details}</Typography>
        </TableContainer>
          

                <Button onClick={()=>handleClick('/market-place/'+pdata?.collection)} disabled={pdata?.amount <= 0} variant={"contained"} sx={{borderRadius:50, fontWeight:'bold'}}  color={"primary"} disableElevation>
                  Buy Now
                  <img src={`${ASSET_IMAGES}/web3/eth.png`} alt="Image 1" style={{ marginLeft: 5, width:'20px' }} />
                  <img src={`${ASSET_IMAGES}/web3/matic.png`} alt="Image 2" style={{ marginLeft: 5, width:'20px' }} />
                  </Button>

            </CardContent>
        </Card>
    )
}

export default PackageBox
