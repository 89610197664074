import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActionArea, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import React, { useContext } from 'react'


const SingleProduct = ({item,imageurl}) => {

  const { addToCart, isLoading, setIsLoading } = useContext(CustomProvider);

  return (

    <Grid item xs={12} sm={6} md={3} key={item.id}>
    <Card>
      <CardActionArea>
        <CardContent>
          <img
            height="100%"
            width="100%"
            src={`${imageurl}${item.picture}`}
            alt={item.title}
            style={{
              borderRadius: "5px",
              border: "none",
              boxShadow: "0px 0px 4px 0px ",
            }}
          />
          <Typography
            pt={2}
            variant="h5"
            fontWeight="bold"
            color="darkgray"
          >
            {item.title}
          </Typography>
          <Typography variant="h6" color="darkgray">
            PVS: {item.pvs}
          </Typography>
          <Typography variant="h6" sx={{textAlign:'center',color:'red'}} color="darkgray">
          ${item.price}
          </Typography>

          <LoadingButton
            variant="contained"
            color="warning"
            sx={{ backgroundColor: "#a785ed" }}
            onClick={() => addToCart(item)}
            disabled={isLoading === item.id}
            loading={isLoading === item.id}
          >

              Add to Cart
          </LoadingButton>
        </CardContent>
      </CardActionArea>
    </Card>
  </Grid>
  )
}

export default SingleProduct