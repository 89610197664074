import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { CircularProgress, Grid } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import { useTranslation } from 'react-i18next';
import { ObjectCountRevenue } from 'app/shared/metrics/ObjectCountCards';
import { ASSET_IMAGES } from "../../../utils/constants/paths";

const VISIBLE_FIELDS = ['sr', 'sender', 'amount', 'date'];
const BinaryBonusReport = () => {
  const {t} = useTranslation();
  const [loading,setLoading]=useState(true)
  const [referralbonusdata,setReferralBonusData]=useState([])
  let params = {
    status: 'approved',
    type:'matchingbonus',
    usertype:'receiver'
  };
  const ReferralData =()=>{
    setLoading(true)
    postRequest('/selecttransactions',params,(response) => {
      if(response?.data?.status === "success") {
          setReferralBonusData(response?.data)
          setLoading(false)
      }
      }, (error) => {
          console.log(error?.response?.data); 
          setLoading(false)
      })
  }
    
useEffect(()=>{
    ReferralData();
},[])


const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
      }
  }
}
}




const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
      color: '#8cda24',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}
      // Otherwise filter will be applied on fields such as the hidden column id
      const columns = [
       
        {
          field: "amount",
          headerName: `${t("pages.title.Amount")}`,  
          dataGeneratorUniquenessEnabled: true,
          width: 150,
          
          groupable: false,
          aggregable: false,
          renderCell: (params) => `$${params.value}`
      },

  // {
  //           field: "details",
  //           headerName: "Details",  
  //           dataGeneratorUniquenessEnabled: true,
  //           width: 400,
  //           
  //           groupable: false,
  //           aggregable: false,  
  //       },
        {
            field: "createdat",
            headerName: `${t("pages.title.Date")}`,  
            dataGeneratorUniquenessEnabled: true,
            width: 100,
            
            groupable: false,
            aggregable: false,  
            renderCell:(params) => {
              const dateObject = new Date(params.row.createdat);
            // Extract the year, month, and day components
            const year = dateObject.getFullYear();
            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
            const day = dateObject.getDate().toString().padStart(2, '0');
            // Create a formatted date string (e.g., "YYYY-MM-DD")
            const formattedDate = `${month}/${day}/${year}`;
            return formattedDate
            }  
        }

    ]
    if(loading){
      return <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />
    </Div>
    }
    const rows= referralbonusdata?.data
  return (
    <>

        <Grid container mb={5} spacing={3}>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(referralbonusdata?.history[0]?.week_total || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
              title={t("pages.title.lastweek")}
              color="primary.main"
              icon={<img src={`${ASSET_IMAGES}/web3/wallet.png`} style={{height:'70px'}} className="animate-scale" />}
              vertical={true}
              bgcolor='#111224b5'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(referralbonusdata?.history[0]?.month_total || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
              title={t("pages.title.thismonth")}
              color="info.main"
              icon={<img src={`${ASSET_IMAGES}/web3/dollar.png`} style={{height:'70px'}}  className="animate-scale" />}
              vertical={true}
              bgcolor='#111224b5'

            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(referralbonusdata?.history[0]?.year_total || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
              title={t("pages.title.thisyear")}
              color="success.main"
              icon={<img src={`${ASSET_IMAGES}/web3/dollar.png`} style={{height:'70px'}}  className="animate-scale" />}
              vertical={true}
              bgcolor='#111224b5'

            />
          </Grid>

        </Grid>

        <JumboDemoCard
          title={t("pages.title.WeakLegBonusSummary")}
          wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>

    <Box sx={{ height: 460, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
    </>

  )
}

export default BinaryBonusReport