import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { getsinglemessage, postRequest} from 'backendServices/ApiCalls';
import Div from '@jumbo/shared/Div/Div';
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import ScheduleIcon from '@mui/icons-material/Schedule';

const MessageDetails = ({selectedMessage}) => {



  return (
    <>
    <h4>Sender: {selectedMessage.senderusername === 'all' ? 'Admin' : selectedMessage.senderusername}</h4>
    <h2>{selectedMessage.title}</h2>
    <div dangerouslySetInnerHTML={{ __html: selectedMessage.message }} />

    <br/>
    <br/>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ScheduleIcon fontSize="small" color="action" />
      <span style={{ marginLeft: '4px' }}>
      {format(new Date(selectedMessage.createdat || '2024-01-24T09:07:27.000Z'), 'MM/dd/yyyy')}
      </span>
    </div>
    </>
  )
}

export default MessageDetails