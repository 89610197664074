import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { ContentCopyOutlined } from '@mui/icons-material'
import { Alert, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from 'react-i18next';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';


const ReferralForm = ({randomcode}) => {
    const { t } = useTranslation();
    const { loginUserData, loading, setalertData } = useContext(CustomProvider);
    let userData = loginUserData;
    const [referralLink, setReferralLink] = useState('select a referral leg first')
    const [selectedReferralSide, setSelectedReferralSide] = useState('')
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        navigator.clipboard.writeText(referralLink);
        setalertData({
          show: true,
          message: "Referral link copied to clipboard",
          variant: "success",
        });
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      };
      
  return (

    <JumboCardQuick
      title={t("pages.title.ReferralLink")}
      subheader={""}
      wrapperSx={{ p: 1 }}
      sx={{with:'100%'}}
      headerSx={{ borderBottom: 1, borderBottomColor: "divider" }}
    >
      {userData?.status === "approved" ? (
        <Grid container spacing={2} >
          <Grid item  xs={12}>
          <FormControl  sx={{width:'100%', marginTop:2}}>
                <InputLabel>{t("pages.title.SelectReferralLeg")}</InputLabel>
                <Select
                fullWidth
                  label={t("pages.title.SelectReferralSide")}
                  value={selectedReferralSide}
                  onChange={(e) =>
                    {
                      let referralLegCode
                      referralLegCode = e.target.value === 'L' ? 'L1232738' : 'R12231333'
                    setReferralLink(`${loginUserData?.referrallink}/${randomcode}/${referralLegCode}`)
                    setSelectedReferralSide(e.target.value)
                    }
                  }
                  sx={{width:'100%'}}
                >
                  <MenuItem value="L">{t("pages.title.Left")}</MenuItem>
                  <MenuItem value="R">{t("pages.title.Right")}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item  xs={12}>
          <TextField
          fullWidth
          label={t("pages.title.Inviteuserswiththislink")}
          type="text"
          value={referralLink}
          margin="normal"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton>
                {copied ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <ContentCopyOutlined
                    onClick={handleCopy}
                    color="warning"
                  />
                )}
              </IconButton>
            ),
          }}
        />
          </Grid>
        </Grid>

      ) : (
        <Alert
          icon={<WarningAmberIcon />}
          variant="outlined"
          style={{ color: "yellow" }}
        >
          {t("pages.title.Pleasebuyamembershiptoactivateyourreferrallink")}
        </Alert>
      )}
    </JumboCardQuick>
    )
}

export default ReferralForm